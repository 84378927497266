import React from 'react';
import './notepad.scss';
import { FaStickyNote, FaWindowClose } from "react-icons/fa";
import { Tooltip } from 'react-tippy';
import classnames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'


class Notepad extends React.Component {
    state = {
        show: false,
        value: localStorage.getItem(this.props.lessonKey + "_notepadText")
    }

    setValue = (value) => {
        this.setState({
            value: value
        });
        localStorage.setItem(this.props.lessonKey + "_notepadText", value);
    }

    render() {
        const modules = {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote'],
              
                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent,
              
                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],
            ],
          };
        
        const formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent', 'color', 'background',
            'align', 'font', 'size'
          ];

        return (
            <div className={classnames("notepadBox", this.state.show ? "show" : null)  }>
                <div className="container">
                    <Row className="pb-3" style={{borderBottom: "1px solid #fff"}}>
                        <Col lg={12} className="text-left">
                            <Tooltip
                                title= {this.state.show ? "Ukryj notatnik" : "Pokaż notatnik"}
                                position="top"
                                trigger="mouseenter">
                                {
                                    this.state.show 
                                    ?
                                        <FaWindowClose size={30} onClick={()=> {this.setState({show: !this.state.show})}}/>
                                    :
                                        <FaStickyNote size={30} onClick={()=> {this.setState({show: !this.state.show})}}/>                        
                                }
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row className="mt-3" style={{height: "calc(100% - 70px)"}}>
                        <Col lg={12} style={{height: "100%"}}>
                            <ReactQuill 
                                className = "note-editor"
                                theme="snow" 
                                value={this.state.value} 
                                onChange={this.setValue}
                                modules={modules}
                                formats={formats}/>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default Notepad;