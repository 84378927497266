
import React from 'react';
import { API_BASE_URL } from '../utils/constants';
import Jar from "./Jar";
import Button from 'react-bootstrap/Button';

class JarGame extends React.Component {
  constructor(props){
    super(props);
    this.escFunction = this.escFunction.bind(this);
  }
  escFunction(event){
    if(event.keyCode === 27) {
      this.props.setJarGame(false)
    }
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }

    state = {
        pleasures: [],
        positions: {}
    }

    componentDidMount() {
      
      document.addEventListener("keydown", this.escFunction, false);

        fetch(API_BASE_URL + "/task/jardata/" + this.props.lessonKey)
            .then(res => res.json())
            .then(json => {
                if(json.status === 200) {
                    let selected = [];
                    let options = [];
                    let ownOptions = [];

                    try { selected = JSON.parse(json.obj.answers.data.selected);} catch(e) {}
                    try { options = JSON.parse(json.obj.params.options);} catch(e) {}
                    try { ownOptions = JSON.parse(json.obj.answers.data.ownOptions);} catch(e) {}

                    let pleasures = [];
                    let positions = {};

                    for (var i = 0; i < selected.length; i++) {
                        let s = selected[i];
                        let pleasure = options.find(el => el.id === s);
                        if (!pleasure) {
                            pleasure = ownOptions.find(el => el.id === s);
                        }
                        pleasures.push(pleasure);
                        positions[s] = this.randomPosition();
                    }
                    
                    this.setState({
                        pleasures: pleasures,
                        positions: positions
                    });
                }
            });
    }

    randomPosition = () => {  

        let position = {};
    
        let x = Math.floor(Math.random() * 51);
        let y = Math.floor(Math.random() * 51);
    
        if (Math.floor(Math.random() * 2) === 1) {
          position.left = x + "%";
        } else {
          position.right = x + "%";
        }
    
        if (Math.floor(Math.random() * 2) === 1) {
          position.top = y + "%";
        } else {
          position.bottom = y + "%";
        }
    
        position.transform = "rotate(" + (Math.floor(Math.random() * 180) - 90) + "deg)";
    
        return position;
      }

  render() {
    return (
      <div className={"jargame"} >
          <div className="jargame-box text-center">
            <div style={{height: "80%"}}>
              <Jar lessonKey={this.props.lessonKey} pleasures={this.state.pleasures} positions={this.state.positions}/>
            </div>
            <div>
              <Button className="main" onClick={() => {this.props.setJarGame(false)}}>Zamknij</Button>
            </div>
          </div>
      </div>
    );
  }
}

export default JarGame;