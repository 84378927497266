
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Tooltip } from 'react-tippy';
import { API_BASE_URL } from '../utils/constants';
import { RiQuestionAnswerLine } from "react-icons/ri";


class Form extends React.Component {

    state = {
        isFilled: this.props.isFilled,
        params: {
            accuracy1Mark: null,
            accuracy2Mark: null,
            accuracy3Mark: null,
            accuracy1Text: null,
            accuracy2Text: null,
            accuracy3Text: null,
            exerciseForm: null,
            identification1Mark: null,
            identification2Mark: null,
            identification3Mark: null,
            identification1Text: null,
            identification2Text: null,
            identification3Text: null,
            likeExercise1Mark: null,
            likeExercise2Mark: null,
            likeExercise3Mark: null,
            likeExercise1Text: null,
            likeExercise2Text: null,
            likeExercise3Text: null,
            likeMark: null,
            likeText: null,
            understandableMark: null,
            understandableText: null,
            notUseExercise: null,
            notUseExercise1: null,
            notUseExercise2: null,
            notUseExercise3: null,
            usefullExerciseText: null,
            usefullExericseMark: null,
            usefullMark: null,
            usefullText: null,
            difficulty: null,
            comments: null        
        },
        config: []
    }

    componentDidMount() {
        if (this.props.step === 1) {
            this.setState({config: [
                ["Q", "identification1", 1, "Jak bardzo utożsamiasz się z przeżywanymi przez bohaterów trudnościami?", "ident"],
                ["Q", "accuracy1", 2, "Według Ciebie na ile trafnie historie bohaterów ilustrują przekazaną wiedzę?", "accur"],
                ["Q", "usefull", 3, "Jak bardzo przydatna jest według Ciebie wiedza zawarta w kroku?", "usef1"],
                ["Q", "like", 4, "Jak bardzo podobał Ci się ten krok?", "like1"],
                ["Q", "understandable", 5, "Na ile zrozumiały był sposób przedstawienia informacji w tym kroku?", "unstd"],
                ["Q", "likeExercise1", 6, "Jak bardzo podobało Ci się ćwiczenie zawarte w tym kroku?", "like2", "Exercise1", "Nie użyłem(-am) ćwiczenia"],
                ["Q", "usefullExercise", 7, "Na ile przydatne wydaje Ci się ćwiczenie zawarte w tym kroku?", "usef2", "Exercise1", "Nie użyłem(-am) ćwiczenia"],
                ["T", "difficulty", 8, "Co sprawiło Ci trudność?"],
                ["T", "comments", 9, "Czy masz inne uwagi?"]
            ]});
        }

        if ([2, 4, 6, 9, 11, 12, 14, 15].indexOf(this.props.step) > -1) {
            this.setState({config: [
                ["Q", "identification1", 1, "Jak bardzo utożsamiasz się z przeżywanymi przez bohatera trudnościami?", "ident"],
                ["Q", "accuracy1", 2, "Według Ciebie na ile trafnie historia bohatera ilustruje przekazaną wiedzę?", "accur"],
                ["Q", "usefull", 3, "Jak bardzo przydatna jest według Ciebie wiedza zawarta w kroku?", "usef1"],
                ["Q", "like", 4, "Jak bardzo podobał Ci się ten krok?", "like1"],
                ["Q", "understandable", 5, "Na ile zrozumiały był sposób przedstawienia informacji w tym kroku?", "unstd"],
                ["Q", "likeExercise1", 6, "Jak bardzo podobało Ci się ćwiczenie zawarte w tym kroku?", "like2", "Exercise1", "Nie użyłem(-am) ćwiczenia"],
                ["Q", "usefullExercise", 7, "Na ile przydatne wydaje Ci się ćwiczenie zawarte w tym kroku?", "usef2", "Exercise1", "Nie użyłem(-am) ćwiczenia"],
                ["T", "difficulty", 8, "Co sprawiło Ci trudność?"],
                ["T", "comments", 9, "Czy masz inne uwagi?"]
            ]});
        }

        if (this.props.step === 3) {
            this.setState({config: [
                ["Q", "identification1", 1, "Jak bardzo utożsamiasz się z przeżywanymi przez bohatera trudnościami?", "ident"],
                ["Q", "accuracy1", 2, "Według Ciebie na ile trafnie historia bohatera ilustruje przekazaną wiedzę?", "accur"],
                ["Q", "usefull", 3, "Jak bardzo przydatna jest według Ciebie wiedza zawarta w kroku?", "usef1"],
                ["Q", "like", 4, "Jak bardzo podobał Ci się ten krok?", "like1"],
                ["Q", "understandable", 5, "Na ile zrozumiały był sposób przedstawienia informacji w tym kroku?", "unstd"],
                ["Q", "likeExercise1", 6, "Jak bardzo podobała Ci się animacja do odwracania uwagi w tym kroku?", "like3", "Exercise1", "Nie użyłem(-am) ćwiczenia"],
                ["Q", "likeExercise2", 7, "Jak bardzo podobało Ci się nagranie audio w tym kroku?", "like2", "Exercise2", "Nie użyłem(-am) ćwiczenia"],
                ["Q", "usefullExercise", 8, "Na ile przydatne wydały Ci się ćwiczenia zawarte w tym kroku?", "usef2", ["Exercise1", "Exercise2"]],
                ["T", "difficulty", 9, "Co sprawiło Ci trudność?"],
                ["T", "comments", 10, "Czy masz inne uwagi?"]
            ]});
        }

        if (this.props.step === 5) {
            this.setState({config: [
                ["Q", "identification1", 1, "Jak bardzo utożsamiasz się z przeżywanymi przez Gosię trudnościami?", "ident"],
                ["Q", "accuracy1", 2, "Według Ciebie na ile trafnie historia Gosi ilustruje przekazaną wiedzę?", "accur"],
                ["Q", "identification2", 3, "Jak bardzo utożsamiasz się z przeżywanymi przez Joannę trudnościami?", "ident"],
                ["Q", "accuracy2", 4, "Według Ciebie na ile trafnie historia Joanny ilustruje przekazaną wiedzę?", "accur"],
                ["Q", "usefull", 5, "Jak bardzo przydatna jest według Ciebie wiedza zawarta w kroku?", "usef1"],
                ["Q", "like", 6, "Jak bardzo podobał Ci się ten krok?", "like1"],
                ["Q", "understandable", 7, "Na ile zrozumiały był sposób przedstawienia informacji w tym kroku?", "unstd"],
                ["Q", "likeExercise1", 8, "Jak bardzo podobało Ci się ćwiczenie zawarte w tym kroku?", "like2", "Exercise1", "Nie użyłem(-am) ćwiczenia"],
                ["Q", "usefullExercise", 9, "Na ile przydatne wydaje Ci się ćwiczenie zawarte w tym kroku?", "usef2", "Exercise1", "Nie użyłem(-am) ćwiczenia"],
                ["T", "difficulty", 10, "Co sprawiło Ci trudność?"],
                ["T", "comments", 11, "Czy masz inne uwagi?"]
            ]});
        }

        if (this.props.step === 7) {
            this.setState({config: [
                ["Q", "identification1", 1, "Jak bardzo utożsamiasz się z przeżywanymi przez bohatera trudnościami?", "ident"],
                ["Q", "accuracy1", 2, "Według Ciebie na ile trafnie  historia bohatera ilustruje przekazaną wiedzę?", "accur"],
                ["Q", "usefull", 3, "Jak bardzo przydatna jest według Ciebie wiedza zawarta w kroku?", "usef1"],
                ["Q", "like", 4, "Jak bardzo podobał Ci się ten krok?", "like1"],
                ["Q", "understandable", 5, "Na ile zrozumiały był sposób przedstawienia informacji w tym kroku?", "unstd"],
                ["Q", "likeExercise1", 6, "Na ile podobało Ci się ćwiczenie dotyczące uważnego jedzenia?", "like2", "Exercise1", "Nie użyłem(-am) ćwiczenia"],
                ["Q", "likeExercise2", 7, "Na ile podobało Ci się ćwiczenie dotyczące uważnego mycia rąk?", "like2", "Exercise2", "Nie użyłem(-am) ćwiczenia"],
                ["Q", "likeExercise3", 8, "Na ile podobało Ci się ćwiczenie dotyczące techniki R.A.I.N?", "like2", "Exercise3", "Nie użyłem(-am) ćwiczenia"],
                ["Q", "usefullExercise", 9, "Na ile przydatne wydały Ci się ćwiczenia zawarte w tym kroku?", "usef2", ["Exercise1", "Exercise2", "Exercise3"]],
                ["T", "difficulty", 10, "Co sprawiło Ci trudność?"],
                ["T", "comments", 11, "Czy masz inne uwagi?"]
            ]});
        }

        if (this.props.step === 8) {
            this.setState({config: [
                ["Q", "identification1", 1, "Jak bardzo utożsamiasz się z przeżywanymi przez bohatera trudnościami?", "ident"],
                ["Q", "accuracy1", 2, "Według Ciebie na ile trafnie  historia bohatera ilustruje przekazaną wiedzę?", "accur"],
                ["Q", "usefull", 3, "Jak bardzo przydatna jest według Ciebie wiedza zawarta w kroku?", "usef1"],
                ["Q", "like", 4, "Jak bardzo podobał Ci się ten krok?", "like1"],
                ["Q", "understandable", 5, "Na ile zrozumiały był sposób przedstawienia informacji w tym kroku?", "unstd"],
                ["Q", "likeExercise1", 6, "Jak bardzo podobało Ci się  ćwiczenie “współczująca przerwa”?", "like2", "Exercise1", "Nie użyłem(-am) ćwiczenia"],
                ["Q", "likeExercise2", 7, "Jak bardzo podobało Ci się ćwiczenie polegające na pisaniu do siebie listu?", "like2", "Exercise2", "Nie użyłem(-am) ćwiczenia"],
                ["Q", "usefullExercise", 8, "Na ile przydatne wydały Ci się ćwiczenia zawarte w tym kroku?", "usef2", ["Exercise1", "Exercise2"]],
                ["T", "difficulty", 9, "Co sprawiło Ci trudność?"],
                ["T", "comments", 10, "Czy masz inne uwagi?"]
            ]});
        }

        if (this.props.step === 10) {
            this.setState({config: [
                ["Q", "usefull", 1, "Jak bardzo przydatna jest według Ciebie wiedza zawarta w kroku?", "usef1"],
                ["Q", "like", 2, "Jak bardzo podobał Ci się ten krok?", "like1"],
                ["Q", "understandable", 3, "Na ile zrozumiały był sposób przedstawienia informacji w tym kroku?", "unstd"],
                ["Q", "likeExercise1", 4, "Jak bardzo podobało Ci się ćwiczenie polegające na tworzeniu planu dnia”?", "like2", "Exercise1", "Nie użyłem(-am) ćwiczenia"],
                ["Q", "likeExercise2", 5, "Jak bardzo podobało Ci się ćwiczenie polegające na stworzeniu listy strategii radzenia sobie?", "like2", "Exercise2", "Nie użyłem(-am) ćwiczenia"],
                ["Q", "usefullExercise", 6, "Na ile przydatne wydały Ci się ćwiczenia zawarte w tym kroku?", "usef2", ["Exercise1", "Exercise2"]],
                ["T", "difficulty", 7, "Co sprawiło Ci trudność?"],
                ["T", "comments", 8, "Czy masz inne uwagi?"]
            ]});
        }

        if (this.props.step === 13) {
            this.setState({config: [
                ["Q", "identification1", 1, "Jak bardzo utożsamiasz się z przeżywanymi przez Edytę trudnościami?", "ident"],
                ["Q", "accuracy1", 2, "Według Ciebie na ile trafnie historia Edyty ilustruje przekazaną wiedzę?", "accur"],
                ["Q", "identification2", 3, "Jak bardzo utożsamiasz się z przeżywanymi przez Elżbietę trudnościami?", "ident"],
                ["Q", "accuracy2", 4, "Według Ciebie na ile trafnie historia Elżbietę ilustruje przekazaną wiedzę?", "accur"],
                ["Q", "identification3", 5, "Jak bardzo utożsamiasz się z przeżywanymi przez Jurka trudnościami?", "ident"],
                ["Q", "accuracy3", 6, "Na ile trafnie, Twoim zdaniem, historia Jurka ilustruje przekazaną wiedzę?", "accur"],
                ["Q", "usefull", 7, "Jak bardzo przydatna jest według Ciebie wiedza zawarta w kroku?", "usef1"],
                ["Q", "like", 8, "Jak bardzo podobał Ci się ten krok?", "like1"],
                ["Q", "understandable", 9, "Na ile zrozumiały był sposób przedstawienia informacji w tym kroku?", "unstd"],
                ["Q", "likeExercise1", 10, "Jak bardzo podobało Ci się ćwiczenie polegające na określeniu swoich ról poza pracą?", "like2", "Exercise1", "Nie dotyczy"],
                ["Q", "likeExercise2", 11, "Jak bardzo podobało Ci się ćwiczenie polegające na określeniu swoich obowiązków zawodowych i aktywności w przerwach od pracy?", "like2", "Exercise2", "Nie dotyczy"],
                ["Q", "likeExercise3", 12, "Jak bardzo podobała Ci się przedstawiona lista rzeczy, które możesz zrobić aby zminimalizować ryzyko zakażenia?", "like3", "Exercise3", "Nie dotyczy"],
                ["Q", "usefullExercise", 13, "Na ile przydatne wydały Ci się ćwiczenia zawarte w tym kroku?", "usef2", ["Exercise1", "Exercise2", "Exercise3"]],
                ["T", "difficulty", 14, "Co sprawiło Ci trudność?"],
                ["T", "comments", 15, "Czy masz inne uwagi?"]
            ]});
        }
        
        if (this.props.step === 16) {
            this.setState({config: [
                ["Q", "identification1", 1, "Jak bardzo utożsamiasz się z przeżywanymi przez Patryka trudnościami?", "ident"],
                ["Q", "accuracy1", 2, "Według Ciebie na ile trafnie historia Patryka ilustruje przekazaną wiedzę?", "accur"],
                ["Q", "identification2", 3, "Jak bardzo utożsamiasz się z przeżywanymi przez Janka trudnościami?", "ident"],
                ["Q", "accuracy2", 4, "Według Ciebie na ile trafnie historia Janka ilustruje przekazaną wiedzę?", "accur"],
                ["Q", "usefull", 5, "Jak bardzo przydatna jest według Ciebie wiedza zawarta w kroku?", "usef1"],
                ["Q", "like", 6, "Jak bardzo podobał Ci się ten krok?", "like1"],
                ["Q", "understandable", 7, "Na ile zrozumiały był sposób przedstawienia informacji w tym kroku?", "unstd"],
                ["T", "difficulty", 8, "Co sprawiło Ci trudność?"],
                ["T", "comments", 9, "Czy masz inne uwagi?"]
            ]});
        }
    }

    getOptionClass = (type, value) => {
        return this.state.params[type] === value ? "selected" : null;
    }

    setParamValue = (type, value) => {
        let p = this.state.params;
        if (p[type] !== value) {
            p[type] = value;
        } else {
            p[type] = null;
        }
        
        this.setState({params: p});
    }

    getCheckboxState = (type) => {
        return this.state.params[type] === true;
    }

    toggleCheckbox = (type) => {
        let p = this.state.params;
        p[type] = (p[type] !== true);
        this.setState({params: p});
    }
    
    getQuestion = (key, step, text, ar, notuse, notuseText) => {
        const answers = {
            ident: ["w ogóle się nie utożsamiam", "w niewielkim stopniu się utożsamiam", "trochę się utożsamiam", "bardzo się utożsamiam", "w pełni się utożsamiam"],
            accur: ["zupełnie nietrafnie", "niezbyt trafnie", "dość trafnie", "trafnie", "bardzo trafnie"],
            usef1: ["zupełnie nieprzydatna", "niezbyt przydatna", "dość przydatna", "przydatna", "bardzo przydatna"],
            usef2: ["zupełnie nieprzydatne", "niezbyt przydatne", "dość przydatne", "przydatne", "bardzo przydatne"],
            like1: ["zupełnie mi się nie podobał", "niezbyt mi się podobał", "całkiem mi się podobał", "podobał mi się", "bardzo mi się podobał"],
            like2: ["zupełnie mi się nie podobało", "niezbyt mi się podobało", "całkiem mi się podobało", "podobało mi się", "bardzo mi się podobało"],
            like3: ["zupełnie mi się nie podobała", "niezbyt mi się podobała", "całkiem mi się podobała", "podobała mi się", "bardzo mi się podobała"],
            unstd: ["zupełnie niezrozumiały", "niezbyt zrozumiały", "dość zrozumiały", "zrozumiały", "bardzo zrozumiały"]
        }

        return (
            <Row key={key + "-" + step} className="mt-4">
                <Col lg={12}>
                    <h6>{ step }. {text}</h6>
                    {
                        notuse && notuseText
                        ?
                        <div className="checkbox">
                            <input 
                                id={"notUse" + notuse}
                                name={"notUse" + notuse} 
                                type="checkbox"
                                checked = { this.getCheckboxState("notUse" + notuse) }
                                onChange = { () => { this.toggleCheckbox("notUse" + notuse) } }/>
                            <label htmlFor={"notUse" + notuse}>{notuseText}</label>
                        </div>
                        :
                        null
                    }
                    <div className="answers">
                        <div onClick={()=>{this.setParamValue(key + "Mark", 1)}} className={this.getOptionClass(key + "Mark", 1)}><i>1</i><span>{answers[ar][0]}</span></div>
                        <div onClick={()=>{this.setParamValue(key + "Mark", 2)}} className={this.getOptionClass(key + "Mark", 2)}><i>2</i><span>{answers[ar][1]}</span></div>
                        <div onClick={()=>{this.setParamValue(key + "Mark", 3)}} className={this.getOptionClass(key + "Mark", 3)}><i>3</i><span>{answers[ar][2]}</span></div>
                        <div onClick={()=>{this.setParamValue(key + "Mark", 4)}} className={this.getOptionClass(key + "Mark", 4)}><i>4</i><span>{answers[ar][3]}</span></div>
                        <div onClick={()=>{this.setParamValue(key + "Mark", 5)}} className={this.getOptionClass(key + "Mark", 5)}><i>5</i><span>{answers[ar][4]}</span></div>
                    </div>
                    <div>
                        <textarea 
                            value={ this.state[key + "Text"] } 
                            onChange={(ev) => this.setParamValue(key + "Text", ev.target.value) } 
                            placeholder="+ dodaj swoje uwagi uzupełnienie punktu jest dobrowolne">
                        </textarea>
                    </div>
                </Col>
            </Row>
        )
    }

    getTextbox = (key, step, text) => {
        return (
            <Row key={key + "-" + step} className="mt-4">
                <Col lg={12}>
                    <h6>{ step }. {text}</h6>
                    <div>
                        <textarea 
                            value={ this.state[key] } 
                            onChange={(ev) => this.setParamValue(key, ev.target.value) } 
                            placeholder="+ dodaj swoje uwagi uzupełnienie punktu jest dobrowolne">
                        </textarea>
                    </div>
                </Col>
            </Row>
        )
    }

    getExerciseForm = (key, step, text) => {
        return (
            <Row key={key + "-" + step} className="mt-4">
                <Col lg={12}>
                    <h6>{ step }. {text}</h6>
                    <div>
                        <div className="checkbox">
                            <input 
                                id={key+"-Text"} 
                                name={key+"-Text"} 
                                type="checkbox"
                                checked = { this.state.params[key] === "tekstowa" }
                                onChange = { () => { this.setParamValue(key, "tekstowa") } }/>
                            <label htmlFor={key+"-Text"}>tekstowej</label>
                        </div>
                        <div className="checkbox">
                            <input 
                                id={key+"-Audio"} 
                                name={key+"-Audio"} 
                                type="checkbox"
                                checked = { this.state.params[key] === "audio" }
                                onChange = { () => { this.setParamValue(key, "audio") } }/>
                            <label htmlFor={key+"-Audio"} >audio</label>
                        </div>
                        <div className="checkbox">
                            <input 
                                id={key+"-Nothing"} 
                                name={key+"-Nothing"} 
                                type="checkbox"
                                checked = { this.state.params[key] === "nie korzystałem" }
                                onChange = { () => { this.setParamValue(key, "nie korzystałem") } }/>
                            <label htmlFor={key+"-Nothing"} >nie korzystałem z ćwiczenia</label>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
    
    getForm = (step) => {
        let ret = [];
        let c = this.state.config;

        for (let i = 0; i < c.length; i++) {
            if (c[i][0] === "Q") {
                ret.push(this.getQuestion(c[i][1], c[i][2], c[i][3], c[i][4], c[i][5], c[i][6]));
            }
            if (c[i][0] === "T") {
                ret.push(this.getTextbox(c[i][1], c[i][2], c[i][3]));
            }
            if (c[i][0] === "F") {
                ret.push(this.getExerciseForm(c[i][1], c[i][2], c[i][3]));
            }
        }

        return ret;
    }

    validateForm = () => {
        let c = this.state.config;
        let p = this.state.params;

        for (var i = 0; i < c.length; i++) {
            if (c[i][0] === "T") {
                continue;
            }
            
            if (!p[c[i][1] + "Mark"]) {
                if (c[i][5]) {
                    if (typeof(c[i][5]) === "string") {
                        if (!p["notUse" + c[i][5]]) {
                            return false;
                        }
                    } else if (typeof(c[i][5]) === "object") {
                        for (let j = 0; j < c[i][5].length; j++) {
                            if (!p["notUse" + c[i][5][j]]) {
                                return false;
                            }
                        }
                    }
                } else {
                    return false;
                }
            }
        }
        
        return true;
    }

    submitForm = () => {
        if (!this.validateForm()) {
            return;
        }

        let data = {
            lessonKey: this.props.lessonKey,
            ...this.state.params
        }


        fetch(API_BASE_URL + "/task/form", {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        });

        this.setState({isFilled: true});
    }

    render() {
        if (this.state.isFilled) {
          return (
              <div className="form-box">
                  <Row className="header">
                      <Col lg={12} className="text-center">
                          <h3>Dziękujemy za wypełnienie ankiety</h3>
                      </Col>
                  </Row>
              </div>
          )
        }
        
      return (
        <div className="form-box">
            <Row className="header">
                <Col lg={12} className="text-center">
                  <h4><RiQuestionAnswerLine /> ANKIETA</h4>
                  <p>Oceń proszę, co myślisz o przedstawionej treści</p>
                </Col>
            </Row>
            
            { this.getForm(this.props.step) }
  
            <Row className="save-box">
                <Col lg={12}>
                {
                    this.validateForm() 
                    ?
                      <Button className="main" onClick={() => {this.submitForm()}}>Zapisz odpowiedzi</Button>
                    :
                      <Tooltip
                          title="Prosimy o udzielenie wszystkich ocen przed wysłaniem ankiety"
                          position="top"
                          trigger="click">
                              <Button className="main">Zapisz odpowiedzi</Button>
                      </Tooltip>
                }                
                </Col>
            </Row>
        </div>
      );
    }
  }

export default Form;