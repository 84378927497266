import React from 'react';
import styles from './extension1.module.scss';
import classnames from 'classnames';
import { Tooltip } from 'react-tippy';

class Extension1 extends React.Component {

    render() {
        return (
            <div className = {classnames(this.props.className, styles.box)}>
                <img src="/images/7_2chlopak.jpg" alt="img"/>
                <div className={ classnames(styles.koncentracja, styles.tooltipster) }>
                    <Tooltip
                        title="Koncentracja - Mindfulness jest dla naszej koncentracji tym, czym dla mięśni trening siłowy - im częściej ćwiczymy, tym lepiej i dłużej potrafimy skupiać naszą uwagę na wykonywanych zadaniach."
                        position="top"
                        trigger="mouseenter">
                        <div></div>
                    </Tooltip>
                </div>
                <div className={ classnames(styles.pamiec, styles.tooltipster) }>
                    <Tooltip
                        title="Pamięć - Badania pokazują, że regularne praktykowanie uważności może poprawić naszą zdolność zapamiętywania i jasnego myślenia. Co więcej, zapobiega również pogarszaniu się pamięci związanemu z wiekiem."
                        position="top"
                        trigger="mouseenter">
                        <div></div>
                    </Tooltip>
                </div>
                <div className={ classnames(styles.jakoscSnu, styles.tooltipster) }>
                    <Tooltip
                        title="Jakość snu - w eksperymentach badających wpływ praktykowania uważności zaobserwowano, że uczestnicy, którzy medytowali w ciągu dnia, wieczorem szybciej zasypiali i budzili się bardziej wypoczęci. "
                        position="top"
                        trigger="mouseenter">
                        <div></div>
                    </Tooltip>
                </div>
                <div className={ classnames(styles.nastroj, styles.tooltipster) }>
                    <Tooltip
                        title="Nastrój - Praktyka uważności pomaga nam lepiej radzić sobie z emocjami, dzięki czemu nasze samopoczucie poprawia się i potrafimy skuteczniej stawiać czoło codziennym wyzwaniom."
                        position="top"
                        trigger="mouseenter">
                        <div></div>
                    </Tooltip>
                </div>
                <div className={ classnames(styles.swiadomeDoswiadczanie, styles.tooltipster) }>
                    <Tooltip
                        title="Świadome doświadczanie - Uważność uczy doświadczania świata bezpośrednio, bez komentowania go bezustannie swoimi myślami."
                        position="top"
                        trigger="mouseenter">
                        <div></div>
                    </Tooltip>
                </div>
                <div className={ classnames(styles.tuiTeraz, styles.tooltipster) }>
                    <Tooltip
                        title="Tu i teraz - Mindfulness uczy skupiania się na “tu i teraz” tam, gdzie aktualnie się znajdujemy, zamiast przewidywania przyszłości lub rozpamiętywania minionych wydarzeń."
                        position="top"
                        trigger="mouseenter">
                        <div></div>
                    </Tooltip>
                </div>
                <div className={ classnames(styles.mysliWydarzenia, styles.tooltipster) }>
                    <Tooltip
                        title="Myśli = wydarzenia w umyśle - Dzięki uważności zaczynamy postrzegać swoje myśli jako pojawiające się w umyśle wydarzenia, zamiast traktować je jako obiektywny opis rzeczywistości."
                        position="top"
                        trigger="mouseenter">
                        <div></div>
                    </Tooltip>
                </div>
                <div className={ classnames(styles.stres, styles.tooltipster) }>
                    <Tooltip
                        title="Stres - Praktykowanie mindfulness uczy nas spokojniej i efektywniej reagować na stresujące sytuacje, dzięki czemu obniża się nasze subiektywne odczuwanie stresu."
                        position="top"
                        trigger="mouseenter">
                        <div></div>
                    </Tooltip>
                </div>
                <div className={ classnames(styles.lek, styles.tooltipster) }>
                    <Tooltip
                        title="Lęk - Dzięki zwiększeniu kontroli nad przeżywanymi emocjami i obniżeniu naszego poziomu stresu, praktykowanie uważności sprawia, że rzadziej i mniej intensywnie odczuwamy lęk."
                        position="top"
                        trigger="mouseenter">
                        <div></div>
                    </Tooltip>
                </div>
                <div className={ classnames(styles.depresja, styles.tooltipster) }>
                    <Tooltip
                        title="Depresja - Badania pokazują, że praktykowanie mindfulness nie tylko zapobiega pojawieniu się zaburzeń depresyjnych, ale również łagodzi symptomy u osób już zmagających się z depresją, a także zmniejsza ryzyko wystąpienia nawrotów."
                        position="top"
                        trigger="mouseenter">
                        <div></div>
                    </Tooltip>
                </div>
                <div className={ classnames(styles.zaburzeniaLekowe, styles.tooltipster) }>
                    <Tooltip
                        title="Zaburzenia lękowe - Podobnie jak w przypadku depresji, w leczeniu zaburzeń lękowych mindfulness również okazuje się niezwykle pomocne - zapobiega ich występowaniu oraz łagodzi odczuwane objawy u zmagających się z nimi osób."
                        position="top"
                        trigger="mouseenter">
                        <div></div>
                    </Tooltip>
                </div>
                <div className={ classnames(styles.rozpamietywaniePrzeszlosci, styles.tooltipster) }>
                    <Tooltip
                        title="Rozpamiętywanie przeszłości - Poprzez uczenie skupiania się na chwili obecnej, mindfulness pomaga pozbyć się uporczywych ruminacji i rozmyślania o przeszłości."
                        position="top"
                        trigger="mouseenter">
                        <div></div>
                    </Tooltip>
                </div>
                <div className={ classnames(styles.autopilot, styles.tooltipster) }>
                    <Tooltip
                        title="Autopilot - Praktykowanie uważności pozwala nam wyłączyć “autopilota” w swojej głowie i nauczyć się wykonywania nawet zwyczajnych czynności z uwagą i skupieniem, dzięki czemu możemy w pełni doświadczać uroków codzienności."
                        position="top"
                        trigger="mouseenter">
                        <div></div>
                    </Tooltip>
                </div>
                <div className={ classnames(styles.zamartwianieSie, styles.tooltipster) }>
                    <Tooltip
                        title="Zamartwianie się - Mindfulness, dzięki nauce obserwowania swoich myśli i koncentrowania się na teraźniejszości, pomaga nam przerwać samonakręcającą się spiralę zamartwiania się i czerpać więcej radości z chwili obecnej"
                        position="top"
                        trigger="mouseenter">
                        <div></div>
                    </Tooltip>
                </div>
            </div>
        )
    }
}

export default Extension1;