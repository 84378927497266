import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Wait from './components/Wait';
import Menu from './components/Menu';
import Lesson from './components/Lesson';
import Toolbox from './components/Toolbox';
import { API_BASE_URL, WELCOME_PAGE_URL } from './utils/constants';
import Modal from 'react-bootstrap/Modal';
import { noise } from './resources/js/perlin';
import { BsCircleFill } from "react-icons/bs";
import JarGame from './components/JarGame';
import Notepad from './components/Notepad';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      shapeBackgroundClass: "",
      waiting: true,
      list: null,
      lessonKey: null,
      panicButton: false,
      panicPlay: false,
      requestAnimationFrameID: null,
      modalGame: false,
      modalGameButton: false,
      jarGame: false,
      jarGameButton: false,
      accountType: null,
      showComfortAlert: false
    }
    
    this.ballRef = React.createRef();

    this.url = "/audio/panicbutton.mp3";
    this.audio = new Audio(this.url);
    this.audio.addEventListener('ended', function () {
      this.currentTime = 0;
      this.play();
    }, false);
    this.playPanicText = this.playPanicText.bind(this);
  }
  

  componentDidMount() {

    this.setState({
      lessonKey: this.props.match.params.k
    }, () => {
      this.getLessonData();
    });
  }

  renderBall = (a) => {
    const el = this.ballRef.current;
    if (!el) {
      return;
    } 

    const noiseX = (noise.simplex2(0, a*0.0001) + 1) / 2;
    const noiseY = (noise.simplex2(1, a*0.0001) + 1) / 2;
    
    const x = noiseX * el.parentElement.offsetWidth;
    const y = noiseY * el.parentElement.offsetHeight;
    
    el.style.transform = `translate(${x}px, ${y}px)`;
    let requestAnimationFrameID = requestAnimationFrame(this.renderBall);
    this.setState({
      requestAnimationFrameID: requestAnimationFrameID
    });
  }

  setModalGame = (value) => {
    if (value) {
      let requestAnimationFrameID = requestAnimationFrame(this.renderBall);
      this.setState({
        requestAnimationFrameID: requestAnimationFrameID,
        modalGame: value
      });
    } else {
      if (this.state.requestAnimationFrameID) {
        window.cancelAnimationFrame(this.state.requestAnimationFrameID);
        this.setState({
          requestAnimationFrameID: null,
          modalGame: value
        });
      }
    }
  }

  setJarGame = (value) => {
    this.setState({
      jarGame: value
    });
  }

  changeStep = (lesson) => {
    this.setState({
      lessonKey: lesson.access,
      lesson: null
    }, () => {
      this.getLessonData();
    });
  }

  getLessonData = (callback) => {
    
      fetch(API_BASE_URL + "/access/" + this.state.lessonKey)
      .then(res => res.json())
      .then(json => {
        if(json.status === 200) {
          this.setState({
            waiting: false,
            list: json.obj.list,
            panicButton: json.obj.panicButton,
            modalGameButton: json.obj.modalGameButton,
            jarGameButton: json.obj.jarGameButton,
            accountType: json.obj.accountType
          }, () => {
            fetch(API_BASE_URL + "/lesson/" + this.state.lessonKey)
              .then(response => response.json())
              .then(lesson_json => {
                  if (lesson_json.status === 200) {
                      this.setState({
                        lesson: lesson_json.obj,
                      });

                      if (callback) {
                        callback();
                      }
                  } else {
                      window.location = WELCOME_PAGE_URL;
                  }
              })
              .catch((error) => {
                  window.location = WELCOME_PAGE_URL;
              });
          });
        } else {
          window.location = WELCOME_PAGE_URL;
        }
      })
      .catch((error) => {
        window.location = WELCOME_PAGE_URL;
      });  
  }

  playPanicText = (play) => {
    this.setState({
      panicPlay: play
    });

    if (!play) {
      this.audio.pause();
    } else {
      this.audio.play();
      let data = {
        accessKey: this.state.lessonKey,
        description: "panicButtonPlay"
      };
      fetch(API_BASE_URL + "/task/event", {
          method: 'post',
          body: JSON.stringify(data),
          headers: {
              "Content-Type": "application/json",
          }
      });
    }
  }

  showComfortAlert = () => {
    this.setState({showComfortAlert: true});
  }

  componentWillUnmount() {
    this.audio.pause();
    this.audio = null;
  }

  render() {

    if (this.state.waiting) {
      return (
        <Wait />
      )
    }

    return (

      <React.Fragment>
        <Navbar expanded={this.state.expanded} 
          onToggle={(val) => {this.setState({expanded: val})}}
          id="mainNav" expand="lg" sticky="top">
          <Container>
            <Navbar.Brand style={{width: "100%"}}>
              <Row>
                <Col className="px-0" sm={5} lg={3}>
                  <a href="/">
                    <img className="mx-2" src="/images/logo.svg" alt="logo"/>
                  </a>
                </Col>
                <Col className="px-0" sm={5} lg={3}>
                  <a target="_blank" href="https://www.ncbr.gov.pl/">
                    <img className="mx-2" src="https://myslnazdrowie.pl/wp-content/uploads/2020/07/BR.png" alt="logoBR"/>
                  </a>
                </Col>
                <Col className="px-0" sm={2} lg={6}>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="ml-auto">
                        <Nav.Link href="https://myslnazdrowie.pl/">Strona główna</Nav.Link>
                        <Nav.Link href="https://myslnazdrowie.pl/kontakt/">Kontakt</Nav.Link>                            
                      </Nav>
                  </Navbar.Collapse>
                </Col>
              </Row>
            </Navbar.Brand>
            
          </Container>
        </Navbar>
        <main>
          <Row className="mx-0 position-relative overflow-hidden">
            <Col lg={3}>
              <Menu changeStep={ this.changeStep } list={ this.state.list } activeLessonKey={ this.state.lessonKey }/>
            </Col>
            <Col lg={9}>
              <Lesson 
                lessonKey={ this.state.lessonKey } 
                lesson={ this.state.lesson } 
                panicButton={ this.state.panicButton } 
                playPanicText={ this.playPanicText } 
                panicPlay={ this.state.panicPlay } 
                setModalGame={this.setModalGame} 
                modalGameButton={this.state.modalGameButton} 
                jarGameButton={this.state.jarGameButton}
                accountType={this.state.accountType}
                getLessonData={this.getLessonData}
                list={ this.state.list }/>
            </Col>
          </Row>
        </main>
        {/*<section className="media">
          <Container>
            <Row>
              <Col lg={{span: 2, offset: 1}} className="social">
                <Row><h6 className="mx-auto mt-3 mb-3">Media społecznościowe</h6></Row>
                <Row className="my-2 element"><AiFillFacebook size={25} className="mr-2"/><span>Facebook</span></Row>
                <Row className="my-2 element"><AiFillInstagram size={25} className="mr-2"/><span>Instagram</span></Row>
                <Row className="my-2 element"><AiFillYoutube size={25} className="mr-2"/><span>Youtube</span></Row>
              </Col>
              <Col lg={{span: 4, offset: 1}} className="contact text-center px-5">
                <Row><h6 className="mx-auto mt-3 mb-3">Kontakt</h6></Row>
                <Form>
                  <div className="form-group">
                    <input type="text" className="form-input border-bottom" placeholder="Adres email"/>
                    <span className="border-bottom-animation center"></span>
                  </div>
                  <div className="form-group">
                    <textarea type="text" className="form-input border-bottom" placeholder="Wiadomość"></textarea>
                    <span className="border-bottom-animation center textarea"></span>
                  </div>
                  <Button className="main">Wyślij</Button>
                </Form>
              </Col>
              <Col  lg={{span: 3, offset: 1}} className="about text-center">
                <Row><h6 className="mx-auto mt-3 mb-3">O firmie</h6></Row>
                <Row className="text-center">
                  <span className="mx-auto">
                    ATI Labs sp. z o.o.<br/>
                    ul. Głogowska 31/33, 60-702 Poznań
                  </span>
                  <span className="mx-auto mt-3">
                    <a href="https://atilabs.pl">AtiLabs.pl</a>
                  </span>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        */}
        <section>          
          <Row className="logotypes">
              <Col className="p-3" lg={3} sm={6}>
                <img alt="FE" src="/images/FE.png"/>
              </Col>
              <Col className="p-3" lg={3} sm={6} >
                <img alt="RP" src="/images/znak_barw_rp_poziom_szara_ramka_rgb (1).png"/>
              </Col>
              <Col className="p-3" lg={3} sm={6} >
                <img alt="BR" src="/images/BR.png"/>
              </Col>
              <Col className="p-3" lg={3} sm={6}>
                <img alt="UE" src="/images/UE.png"/>
              </Col>
          </Row>
        </section>
        <footer>
          <Row className="mx-auto text-center">
            <Col lg={{span: 6}} className="px-5"><span><a href="https://myslnazdrowie.pl/regulamin/">Regulamin</a></span></Col>
            <Col lg={{span: 6}} className="px-5">Wszelkie prawa zastrzeżone.</Col>
          </Row>
        </footer>
        <Toolbox 
          setModalGame={this.setModalGame} 
          modalGameButton= {this.state.modalGameButton} 
          panicButton={ this.state.panicButton } 
          playPanicText={ this.playPanicText } 
          panicPlay={ this.state.panicPlay }
          jarGameButton= {this.state.jarGameButton}
          setJarGame={this.setJarGame}
          showComfortAlert={this.showComfortAlert}/>
        <Notepad lessonKey = { this.state.lessonKey } />
        <Modal
          show={this.state.modalGame}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => {this.setModalGame(false) }}
        >
          <Modal.Body>    
            <div  className="ball" ref={ this.ballRef }><BsCircleFill size={50}/></div>    
          </Modal.Body>
        </Modal>
        {
          this.state.jarGame ?
          <JarGame lessonKey={this.state.lessonKey}  setJarGame={this.setJarGame}/>
          : null
        }
        {
          this.state.showComfortAlert
          ?
          <div className={"comfort-alert"} onClick={()=> {this.setState({showComfortAlert: false})}}>
            <div>
              Jeśli doświadczasz myśli samobójczych lub agresywnych, zadzwoń pod telefon alarmowy 112. Możesz także udać się do najbliższego ośrodka interwencji kryzysowej lub szpitala psychiatrycznego. Do Twojej dyspozycji są również darmowe linie wsparcia: 800 70 22 22 (Fundacja ITAKA, całodobowo), 116 123 (Instytut Psychologii Zdrowia, w godzinach 14:00-22:00), 116 123 (Kryzysowy Telefon Zaufania dla Dorosłych, w godzinach 14:00-22:00) lub 116 111 (Telefon Zaufania dla Dzieci i Młodzieży, w godzinach 13:00-02:00). 
            </div>
          </div>
          :
          null
        }
      </React.Fragment>
    );
  }
}

export default App;
