import React from 'react';
import styles from './L12.module.scss';
import { Container, Table, Row, Col, Button } from 'react-bootstrap';
import classnames from 'classnames';
import { BsPencil } from "react-icons/bs";
import { SketchPicker } from 'react-color';
import { MdCancel, MdAddCircle } from "react-icons/md";
import { Tooltip } from 'react-tippy';
import swal from 'sweetalert';
import TimePicker from '../components/TimePicker';
import Select from 'react-select'
import Creatable from 'react-select/creatable';
import ReactToPrint from 'react-to-print';

class L12 extends React.Component {
    constructor(props) {
        super(props);
        this.colors = ["#EDBBE8", "#FFCF70", "#8FFF70", "#62D0D0", "#FFE93F", "#B8C6C6"];
        this.days = ["PONIEDZIAŁEK", "WTOREK", "ŚRODA", "CZWARTEK", "PIĄTEK", "SOBOTA", "NIEDZIELA"];
        this.options = JSON.parse(localStorage.getItem(this.props.lessonKey + "_exercise12_options")) || {
            0: ["Telefony do klientów", "Pisanie raportu", "Odpisywanie na e maile", "Wysyłanie materiałów do współpracowników", "Tworzenie materiałów do pracy"
                , "Planowanie pracy"],
            1: ["Nauka", "Odrabianie pracy domowej", "Lektura książek potrzebnych na zajęcia", "Robienie notatek", "Praca nad projektem"],
            2: ["Wstawianie prania", "Gotowanie śniadania/obiadu/kolacji", "Mycie okien", "Odkurzanie/mycie podłogi", "Ścieranie kurzy", "Opiekowanie się roślinami"
                , "Prasowanie", "Porządki w szafach - składanie i układanie ubrań", "Drobne naprawy sprzętów domowych"],
            3: ["Opiekowanie się roślinami", "Oglądanie filmów/seriali", "Wieczorny/poranny spacer", "Granie w gry komputerowe/granie w gry na konsoli", "Czytanie książek"
                , "Rysowanie/malowanie", "Dzień SPA", "Uczenie się nowych rzeczy", "Granie na instrumentach", "Ćwiczenia fizyczne", "Losuj przyjemności"],
            4: ["Oglądanie filmów/seriali z ", "Granie w gry planszowe z ", "Wspólny spacer z ", "Opiekowanie się roślinami z ", "Masaż z ", "Robienie kolaży z "
                , "Wspólne gotowanie z ", "Zabawa w gry ruchowe z ", "Wspólne ćwiczenia fizyczne z ", "Planowanie wspólnych podróży z ", "Losuj przyjemności z "]
        }

        this.state = {
            catmap: JSON.parse(localStorage.getItem(this.props.lessonKey + "_exercise12_catmap")) || {
                0: {
                    name: "Praca",
                    color: this.colors[0],
                    pickColor: false,
                    disabled: true
                },
                1: {
                    name: "Szkoła/studia",
                    color: this.colors[1],
                    pickColor: false,
                    disabled: true
                },
                2: {
                    name: "Obowiązki domowe",
                    color: this.colors[2],
                    pickColor: false,
                    disabled: true
                },
                3: {
                    name: "Czas dla siebie",
                    color: this.colors[3],
                    pickColor: false,
                    disabled: true
                },
                4: {
                    name: "Wspólne przyjemności",
                    color: this.colors[4],
                    pickColor: false,
                    disabled: true
                },
                5: {
                    name: null,
                    color: this.colors[5],
                    pickColor: false
                },
            },
            showModal: false,
            calendar: JSON.parse(localStorage.getItem(this.props.lessonKey + "_exercise12_calendar")) || {
              0: [],
              1: [],
              2: [],
              3: [],
              4: [],
              5: [],
              6: []
            },
            addForm: {
              day: null,
              option: null,
              minTime: 720,
              maxTime: 780,
              person: ""
            },
            editForm: {
              show: false,
              day: null,
              option: null,
              minTime: null,
              maxTime: null,
              cat: null,
              WD: null,
              WD_index: null,
              person: ""
            }
        }
    }

    showModal = (day) => {
        let addForm = this.state.addForm;
        addForm.day = day;
        this.setState({
          addForm: addForm,
          showModal: true
        });
      }
    
    toggleColorPicker = (key, value) => {
        let catmap = this.state.catmap;
        if (value === undefined) {
          value = !catmap[key].pickColor;
        }
        catmap[key].pickColor = value;
        this.setState({catmap: catmap}, () => {
          localStorage.setItem(this.props.lessonKey + "_exercise12_catmap", JSON.stringify(this.state.catmap));
        });
      }

      handleColorChange = (key, color) => {
        let catmap = this.state.catmap;
        catmap[key].color = color.hex;
        this.setState({catmap: catmap}, () => {
          localStorage.setItem(this.props.lessonKey + "_exercise12_catmap", JSON.stringify(this.state.catmap));
        });
      }

      addCat = () => {
        let catmap = this.state.catmap;
        let maxID = Math.max.apply(null, Object.keys(catmap));
        if (maxID < 0 || maxID > 100000) {
          maxID = 0;
        }
        maxID++;
        
        catmap[maxID] = {
          name: "",
          color: this.colors[maxID % this.colors.length],
          pickColor: false
        } 
        this.setState({catmap: catmap}, () => {
          localStorage.setItem(this.props.lessonKey + "_exercise12_catmap", JSON.stringify(this.state.catmap));
        });
      }

      removeCat = (key) => {
        let C = this.state.calendar;
        for (var i = 0; i < Object.keys(C).length; i++) {
          let k = Object.keys(C)[i];
          for (var j = 0; j < C[k].length; j++) {
            if (C[k][j].cat === key) {
              swal("Nie możesz skasować, ponieważ ta kategoria została użyta w kalendarzu!");
              return;
            }
          }
        }
        let catmap = this.state.catmap;
        delete catmap[key];
        this.setState({catmap: catmap}, () => {
          localStorage.setItem(this.props.lessonKey + "_exercise12_catmap", JSON.stringify(this.state.catmap));
        });
      }

      generateCell = (C, WD) => {
        if (!C) {
          return <td></td>;
        }
    
        return (
          <td style={{color: this.getContrastYIQ(this.state.catmap[C.cat].color), backgroundColor: this.state.catmap[C.cat].color}}>
            <div className={styles.notice} onClick={()=> { this.editCalendarOption(C, WD) }} style={{color: this.getContrastYIQ(this.state.catmap[C.cat].color), backgroundColor: this.state.catmap[C.cat].color}} >
              <div>{this.getTimeString(C.minTime)}-{this.getTimeString(C.maxTime)}</div>
              <div>{this.state.catmap[C.cat].name}</div>
              <div>{this.options[C.cat][C.option]} {
                // eslint-disable-next-line
                C.cat == 4 ? C.person : "" 
                }
              </div>
            </div>
          </td>
        );
      }

      getCalendarRows = () => {
        let maxLength = 0;
        let C = this.state.calendar;
        for (var i = 0; i < Object.keys(C).length; i++) {
          if (C[Object.keys(C)[i]].length > maxLength) {
            maxLength = C[Object.keys(C)[i]].length;
          }
          C[i].sort((a, b) => { return a.minTime - b.minTime !== 0 ? a.minTime - b.minTime : a.maxTime - b.maxTime});
        }
    
        
        var r = [];
        for (var j = 0; j < maxLength; j++) {
          r.push(<tr className={styles.noborder} key={j}>
            
              { this.generateCell(C[0][j], 0)}  
            
              { this.generateCell(C[1][j], 1)}         
            
              { this.generateCell(C[2][j], 2)}         
            
              { this.generateCell(C[3][j], 3)}         
            
              { this.generateCell(C[4][j], 4)}         
           
              { this.generateCell(C[5][j], 5)}         
           
              { this.generateCell(C[6][j], 6)}         
           
          </tr>);
        }
        return r;
      }

      
  arrayToSelect = (arr) => {
    let r = [];
    if (arr) {
      for (var i = 0; i < arr.length; i++) {
        r.push({value: i, label: arr[i]});
      }
    }
    return r;
  }

  handleFormChange = (type, value) => {
    let form = this.state.editForm.show ? this.state.editForm : this.state.addForm;
    if (type === "option" && value.__isNew__) {
      this.options[form.cat.value] = this.options[form.cat.value] || [];
      this.options[form.cat.value].push(value.label);
      value.value = this.options[form.cat.value].indexOf(value.label);
      localStorage.setItem(this.props.lessonKey + "_exercise12_options", JSON.stringify(this.options));
    }
    form[type] = value;
    if (type === "cat") {
      form.option = null;
    }
    this.setState({addForm: form});
    
  }

  addFormToTable = () => {

    if (!this.state.addForm.option) {
        swal("Wybierz zajęcie przed dodaniem do kalendarza");
        return;
    }
    if (!this.state.addForm.minTime) {
        swal("Wybierz godzinę rozpoczęcia");
        return;
    }
    if (!this.state.addForm.maxTime) {
        swal("Wybierz godzinę zakończenia");
        return;
    }
    if (!this.state.addForm.cat) {
        swal("Wybierz rodzaj przed dodaniem do kalendarza");
        return;
    }

    let calendar = this.state.calendar;
    calendar[this.state.addForm.day].push({
      option: this.state.addForm.option.value,
      minTime: this.state.addForm.minTime,
      maxTime: this.state.addForm.maxTime,
      cat: this.state.addForm.cat.value,
      person: this.state.addForm.person
    });


    this.setState({
      calendar: calendar,
      showModal: false,
      addForm: {
        day: null,
        cat: null,
        option: null,
        minTime: 720,
        maxTime: 780,
        person: ""
      }
    }, () => {
      localStorage.setItem(this.props.lessonKey + "_exercise12_calendar", JSON.stringify(this.state.calendar));
    });
  }

  getContrastYIQ = (hexcolor) => {
        hexcolor = hexcolor.replace("#", "");
        var r = parseInt(hexcolor.substr(0,2),16);
        var g = parseInt(hexcolor.substr(2,2),16);
        var b = parseInt(hexcolor.substr(4,2),16);
        var yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    getTimeString = (val) => {
        let hour = "00" + Math.floor(val / 60);
        let min = "00" + val % 60;
    
        hour = hour.substring(hour.length - 2);
        min = min.substring(min.length - 2);
        return hour + ":" + min;
      }


      editCalendarOption = (C, WD) => {
        let editForm = this.state.editForm;
        editForm.show = true;
        editForm.option = {value: C.option, label: this.options[C.cat][C.option]};
        editForm.minTime = C.minTime;
        editForm.maxTime = C.maxTime;
        editForm.person = C.person;
        editForm.cat = {value: C.cat, label: this.state.catmap[C.cat].name};
        editForm.day = WD;
        editForm.WD = WD;
        editForm.WD_index = this.state.calendar[WD].indexOf(C);
        this.setState({
          editForm: editForm
        });
      }

      saveEditForm = () => {
        let C = this.state.calendar;
        C[this.state.editForm.WD][this.state.editForm.WD_index].option = this.state.editForm.option.value;
        C[this.state.editForm.WD][this.state.editForm.WD_index].minTime = this.state.editForm.minTime;
        C[this.state.editForm.WD][this.state.editForm.WD_index].maxTime = this.state.editForm.maxTime;
        C[this.state.editForm.WD][this.state.editForm.WD_index].cat = this.state.editForm.cat.value;
        C[this.state.editForm.WD][this.state.editForm.WD_index].person = this.state.editForm.person;
        this.setState({
          calendar: C
        }, () => {
          localStorage.setItem(this.props.lessonKey + "_exercise12_calendar", JSON.stringify(this.state.calendar));
          this.cancelEditForm();
        });
      }

      cancelEditForm = () => {
        this.setState({
          editForm: {
            show: false,
            day: null,
            option: null,
            minTime: null,
            maxTime: null,
            cat: null,
            WD: null,
            WD_index: null
          }
        });
      }

      removeEditForm = () => {
        let C = this.state.calendar;
        C[this.state.editForm.WD].splice(this.state.editForm.WD_index, 1);
        this.setState({
          calendar: C
        }, () => {
          localStorage.setItem(this.props.lessonKey + "_exercise12_calendar", JSON.stringify(this.state.calendar));
          this.cancelEditForm();
        });
      }

      setName = (key, value) => {
        let catmap = this.state.catmap;
        catmap[key].name = value;
        this.setState({catmap: catmap}, () => {
          localStorage.setItem(this.props.lessonKey + "_exercise12_catmap", JSON.stringify(this.state.catmap));
        });
      }

    render() {
        return (
            <Row className={classnames("exercise-box", styles.exerciseBox12, this.props.className)}>
                <Col lg={12} className="text-center">
                    <h4><BsPencil /> ZADANIE</h4>
                    <p className={ styles.content }>{this.props.params.content}</p>
                    <hr/>
                </Col>
                <Row className="px-5 max-5">
                    <Col lg={12} className="text-center">
                        <h6>Mapa</h6>
                    </Col>
                    {
                        Object.keys(this.state.catmap).map(k => {
                            let cat = this.state.catmap[k];
                            return (
                            <Col key={k} lg={6} className="text-center">
                                <div className={ styles.category }>
                                    <div className={ styles.inside }>
                                    <div className={ styles.name }>
                                        <input placeholder="Wpisz kategorię" value={cat.name || ""} onChange={(ev)=>{ this.setName(k, ev.target.value) }}/>
                                        {
                                            cat.disabled ? null :
                                            <Tooltip
                                                title="Usuń"
                                                position="top"
                                                trigger="mouseenter">
                                                <MdCancel onClick={() => { this.removeCat(k) }} />
                                                </Tooltip>
                                        }
                                        
                                    </div>
                                    <div className={ styles.color } 
                                        style={{backgroundColor: cat.color}}
                                        onClick={()=>{this.toggleColorPicker(k)}}>
                                        &nbsp;
                                    </div>
                                    </div>
                                    {
                                        cat.pickColor 
                                    ?
                                    <>
                                        <div className={ styles.cover } onClick={()=>{this.toggleColorPicker(k, false)}}/>
                                        <SketchPicker color={cat.color} onChange={ (color) => { this.handleColorChange(k, color) } }/>
                                    </>
                                    :
                                    null
                                    }
                                </div>
                            </Col>
                            )
                        })
                        }
                </Row>
                <Col lg={12} className="text-center">
                    <Button onClick={()=> {this.addCat()}} className="main small">Dodaj kategorię</Button>
                    <ReactToPrint
                        bodyClass={styles.exerciseBox12}
                            copyStyles = {true}
                            trigger={() => {
                            return <Button className="main small">Drukuj grafik</Button>
                            }}
                            content={() => this.tableRef}
                        />
                </Col>
                <Col lg={12}>
                    <div style={{overflow: "auto"}}>
                        <Table ref={el => (this.tableRef = el)}  striped bordered hover className="mt-5">
                            <thead>
                            <tr>
                                <th>PONIEDZIAŁEK</th>
                                <th>WTOREK</th>
                                <th>ŚRODA</th>
                                <th>CZWARTEK</th>
                                <th>PIĄTEK</th>
                                <th>SOBOTA</th>
                                <th>NIEDZIELA</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.getCalendarRows()
                            }
                            <tr>
                                <td>
                                <Tooltip
                                    title="Dodaj"
                                    position="top"
                                    trigger="mouseenter">
                                    <MdAddCircle onClick={()=>{this.showModal(0)}} size={20}/>
                                </Tooltip>
                                </td>
                                <td>
                                <Tooltip
                                    title="Dodaj"
                                    position="top"
                                    trigger="mouseenter">
                                    <MdAddCircle onClick={()=>{this.showModal(1)}} size={20}/>
                                </Tooltip>
                                </td>
                                <td>
                                <Tooltip
                                    title="Dodaj"
                                    position="top"
                                    trigger="mouseenter">
                                    <MdAddCircle onClick={()=>{this.showModal(2)}} size={20}/>
                                </Tooltip>
                                </td>
                                <td>
                                <Tooltip
                                    title="Dodaj"
                                    position="top"
                                    trigger="mouseenter">
                                    <MdAddCircle onClick={()=>{this.showModal(3)}} size={20}/>
                                </Tooltip>
                                </td>
                                <td>
                                <Tooltip
                                    title="Dodaj"
                                    position="top"
                                    trigger="mouseenter">
                                    <MdAddCircle onClick={()=>{this.showModal(4)}} size={20}/>
                                </Tooltip>
                                </td>
                                <td>
                                <Tooltip
                                    title="Dodaj"
                                    position="top"
                                    trigger="mouseenter">
                                    <MdAddCircle onClick={()=>{this.showModal(5)}} size={20}/>
                                </Tooltip>
                                </td>
                                <td>
                                <Tooltip
                                    title="Dodaj"
                                    position="top"
                                    trigger="mouseenter">
                                    <MdAddCircle onClick={()=>{this.showModal(6)}} size={20}/>
                                </Tooltip>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                        </div>
                </Col>
                {
                    this.state.showModal
                    ?
                    <div className={styles.modal}>
                        <div className={styles.container}>
                        <Container>
                            <Row className="mb-3">
                            <Col lg={12}>
                                <h4>{this.days[this.state.addForm.day]}</h4>
                            </Col>
                            </Row>
                            <Row>
                            <Col className="py-4 text-right" lg={3} sm={3}>
                                Od godziny
                            </Col>
                            <Col className="text-left" lg={3} sm={3}>
                                <TimePicker time={this.state.addForm.minTime} maxTime={this.state.addForm.maxTime} onChange={(value => this.handleFormChange("minTime", value))} />
                            </Col>
                            <Col className="py-4 text-right" lg={3} sm={3}>
                                Do godziny
                            </Col>
                            <Col className="text-left" lg={3} sm={3}>
                                <TimePicker time={this.state.addForm.maxTime} minTime={this.state.addForm.minTime} onChange={(value => this.handleFormChange("maxTime", value))} />
                            </Col>
                            </Row>
                            <Row className="my-3 text-center">
                            <Col className="my-1" lg={12}>
                                <Select placeholder="Wybierz rodzaj" value={this.state.addForm.cat} onChange={(val) => this.handleFormChange("cat", val)} options={Object.keys(this.state.catmap).map(k => {return {value: k, label: this.state.catmap[k].name}})} />
                            </Col>
                            <Col className="my-1" lg={12}>
                                <Creatable placeholder="Wybierz zajęcie lub wpisz własne" value={this.state.addForm.option} onChange={(val) => this.handleFormChange("option", val)} options={this.arrayToSelect(this.state.addForm.cat ? this.options[this.state.addForm.cat.value] : null)} />
                            </Col>
                            {
                                // eslint-disable-next-line
                                this.state.addForm.cat && this.state.addForm.cat.value == 4
                                ?
                                <Col className="my-1" lg={12}>
                                    <input value={ this.state.addForm.person } onChange={(ev) => { this.handleFormChange("person", ev.target.value)}} className={styles.minput} type="text" placeholder="Z kim spędzisz ten czas"/>
                                </Col>
                                :
                                null
                            } 
                            </Row>
                            <Row className="my-3">
                            <Col lg={12}>
                                <Button onClick={()=>{this.setState({showModal: false})}} className="main">Anuluj</Button>
                                <Button onClick={() => {this.addFormToTable() }} className="main">Zapisz</Button>
                            </Col>
                            </Row>
                        </Container>
                        </div>
                    </div>
                    :
                    null
                }
                {
                    this.state.editForm.show
                    ?
                    <div className={styles.modal}>
                        <div className={styles.container}>
                        <Container>
                            <Row className="mb-3">
                            <Col lg={12}>
                                <h4>{this.days[this.state.editForm.day]}</h4>
                            </Col>
                            </Row>
                            <Row>
                            <Col className="py-4 text-right" lg={3}>
                                Od godziny
                            </Col>
                            <Col className="text-left" lg={3}>
                                <TimePicker time={this.state.editForm.minTime} maxTime={this.state.editForm.maxTime} onChange={(value => this.handleFormChange("minTime", value))} />
                            </Col>
                            <Col className="py-4 text-right" lg={3}>
                                Do godziny
                            </Col>
                            <Col className="text-left" lg={3}>
                                <TimePicker time={this.state.editForm.maxTime} minTime={this.state.editForm.minTime} onChange={(value => this.handleFormChange("maxTime", value))} />
                            </Col>
                            </Row>
                            <Row className="my-3 text-center">
                            <Col className="my-1" lg={12}>
                                <Select placeholder="Wybierz rodzaj" 
                                value={this.state.editForm.cat} 
                                onChange={(val) => this.handleFormChange("cat", val)} 
                                options={Object.keys(this.state.catmap).map(k => {return {value: k, label: this.state.catmap[k].name}})} />
                            </Col>
                            <Col className="my-1" lg={12}>
                                <Creatable placeholder="Wybierz zajęcie lub wpisz własne" value={this.state.editForm.option} onChange={(val) => this.handleFormChange("option", val)} options={this.arrayToSelect(this.state.editForm.cat ? this.options[this.state.editForm.cat.value] : null)} />
                            </Col>
                            {
                                // eslint-disable-next-line
                                this.state.editForm.cat && this.state.editForm.cat.value == 4
                                ?
                                <Col className="my-1" lg={12}>
                                    <input value={ this.state.editForm.person } onChange={(ev) => { this.handleFormChange("person", ev.target.value)}} className={styles.minput} type="text" placeholder="Z kim spędzisz ten czas"/>
                                </Col>
                                :
                                null
                            }                           
                            </Row>
                            <Row className="my-3">
                            <Col lg={12}>
                                <Button onClick={()=>{this.cancelEditForm()}} className="main">Anuluj</Button>
                                <Button onClick={()=>{this.removeEditForm()}} className="main">Usuń</Button>
                                <Button onClick={()=>{this.saveEditForm()}} className="main">Zapisz</Button>
                            </Col>
                            </Row>
                        </Container>
                        </div>
                    </div>
                    :
                    null
                }
            </Row>
        )
    }
}

export default L12;