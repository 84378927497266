import React from 'react';
import styles from './L17.module.scss';
import {Row, Col, Button} from 'react-bootstrap';
import { BsPencil } from "react-icons/bs";
import classnames from 'classnames';
import { API_BASE_URL } from '../utils/constants';
import swal from 'sweetalert';

class L17 extends React.Component {

    state = {
        answers: {},
        submitted: false
    }

    componentDidMount () {
        let answers = {};
        let submitted = false;
        try { 
            answers = JSON.parse(this.props.answers.data.answers);
            submitted = this.props.answers.data.submitted
        } catch (e) {}

        this.setState({answers: answers, submitted: submitted});
    }
    
    checkAnswer = (key, value) => {
        if (this.state.submitted) {
            return;
        }
        let answers = this.state.answers;
        answers[key] = value;
        this.setState({answers: answers}, this.submitForm);
    }

    toggleArrayAnswer = (key, value) => {
        if (this.state.submitted) {
            return;
        }
        let answers = this.state.answers;
        answers[key] = answers[key] || [];
        let index = answers[key].indexOf(value);
        if (index === -1) {
            answers[key].push(value);
        } else {
            answers[key].splice(index, 1);
        }
        this.setState({answers: answers}, this.submitForm);
    }

    getQuestionAnswers = (key, question) => {
        if (question.type === "1-5") {
            return (     
                <>           
                    <div className={styles.answers}>
                        <div className={ classnames("checkbox pl-2") }>
                            <input 
                                type={"radio"}
                                id={key + "1"} 
                                name={key} 
                                onChange={() => this.checkAnswer(key, 1)}
                                checked = { this.state.answers[key] === 1 }
                            />
                            <label htmlFor={key + "1"}>
                                <span>1 - Zupełnie mi się nie podobały</span>
                            </label>
                        </div>
                    </div>        
                    <div className={styles.answers}>
                        <div className={ classnames("checkbox pl-2") }>
                            <input 
                                type={"radio"}
                                id={key + "2"} 
                                name={key} 
                                onChange={() => this.checkAnswer(key, 2)}
                                checked = { this.state.answers[key] === 2 }
                            />
                            <label htmlFor={key + "2"}>
                                <span>2 - Niezbyt mi się podobały</span>
                            </label>
                        </div>
                    </div>    
                    <div className={styles.answers}>
                        <div className={ classnames("checkbox pl-2") }>
                            <input 
                                type={"radio"}
                                id={key + "3"} 
                                name={key} 
                                onChange={() => this.checkAnswer(key, 3)}
                                checked = { this.state.answers[key] === 3 }
                            />
                            <label htmlFor={key + "3"}>
                                <span>3 - Całkiem mi się podobały</span>
                            </label>
                        </div>
                    </div>    
                    <div className={styles.answers}>
                        <div className={ classnames("checkbox pl-2") }>
                            <input 
                                type={"radio"}
                                id={key + "4"} 
                                name={key} 
                                onChange={() => this.checkAnswer(key, 4)}
                                checked = { this.state.answers[key] === 4 }
                            />
                            <label htmlFor={key + "4"}>
                                <span>4 - Podobały mi się</span>
                            </label>
                        </div>
                    </div>    
                    <div className={styles.answers}>
                        <div className={ classnames("checkbox pl-2") }>
                            <input 
                                type={"radio"}
                                id={key + "5"} 
                                name={key} 
                                onChange={() => this.checkAnswer(key, 5)}
                                checked = { this.state.answers[key] === 5 }
                            />
                            <label htmlFor={key + "5"}>
                                <span>5 - Bardzo mi się podobały</span>
                            </label>
                        </div>
                    </div>
                </>
            )
        } else if (question.type === "bool") {
            return (
                <>  
                    <div className={styles.answers}>
                        <div className={ classnames("checkbox pl-2") }>
                            <input 
                                type={"radio"}
                                id={key + "1"} 
                                name={key} 
                                onChange={() => this.checkAnswer(key, 1)}
                                checked = { this.state.answers[key] === 1 }
                            />
                            <label htmlFor={key + "1"}>
                                <span>Tak</span>
                            </label>
                        </div>
                    </div>  
                    <div className={styles.answers}>
                        <div className={ classnames("checkbox pl-2") }>
                            <input 
                                type={"radio"}
                                id={key + "0"} 
                                name={key} 
                                onChange={() => this.checkAnswer(key, 0)}
                                checked = { this.state.answers[key] === 0 }
                            />
                            <label htmlFor={key + "0"}>
                                <span>Nie</span>
                            </label>
                        </div>
                    </div>
                </>
            )
        } else if (question.type === "checkbox") {
            let r = [];
            for (let i = 0; i < question.answers.length; i++) {
                r.push(
                    <div key={question.answers[i].id} className={ classnames("checkbox pl-2") }>
                        <input 
                            type={"checkbox"}
                            id={key + question.answers[i].id} 
                            name={key + question.answers[i].id} 
                            onChange={() => this.toggleArrayAnswer(key, question.answers[i].id)}
                            checked = { this.state.answers[key] && this.state.answers[key].indexOf(question.answers[i].id) > -1 ? true : false }
                        />
                        <label htmlFor={key + question.answers[i].id}>
                            <span>{question.answers[i].text}</span>
                        </label>
                    </div>
                )
            }
            return r;
        }
        return null;
    }

    getQuestion = (key, question) => {
        return (
            <div key={key} className="question-box my-3">
                <h6>{question.text}</h6>
                {
                    this.getQuestionAnswers(key, question)
                }
            </div>
        )
    }

    submitForm = () => {
        let data = {
            accessKey: this.props.lessonKey,
            data: {
                answers: JSON.stringify(this.state.answers)
                , submitted: this.state.submitted
            }
        };
    
        fetch(API_BASE_URL + "/task/save", {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        });
    }

    buttonSubmit = () => {
        let correct = true;
        let prefix = (this.props.accountType === "basic" ? "b-" : "r-") + "question-";
        Object.keys(this.props.params).filter(k => k.indexOf(prefix) === 0)
            .map(el => {
                if (this.state.answers[el] === undefined) {
                    correct = false;
                }
                return null;
            });

        if (!correct) {
            swal("", "Prosimy odpowiedzieć na wszystkie pytania przed wysłaniem", "error");
            return null;
        }

        this.setState({submitted:true}, this.submitForm);
    }

    render() {
        let prefix = (this.props.accountType === "basic" ? "b-" : "r-") + "question-";
        return (
            <Row className={classnames("exercise-box", styles.exerciseBox17, this.props.className)}>
                <Col className="text-center">
                    <h4><BsPencil /> ANKIETA KOŃCOWA</h4>
                    <hr/>
                    <div className="text-left">
                        {
                            Object.keys(this.props.params).filter(k => k.indexOf(prefix) === 0).sort((a, b) => parseInt(a.replace(prefix, "")) - parseInt(b.replace(prefix, "")))
                                .map(k => {
                                    let question = JSON.parse(this.props.params[k]);
                                    return this.getQuestion(k, question);
                                })
                        }
                    </div>
                    
                    {
                        this.state.submitted
                        ?
                        <span className={styles.submitted}>Dziękujemy za wysłanie ankiety</span>
                        :
                        <div className="submit-row">
                            <Button onClick={this.buttonSubmit} className="main">Wyślij</Button>
                        </div> 
                    }
                    
                </Col>
            </Row>
        )
    }
}

export default L17;