
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styles from './L6.module.scss';
import { API_BASE_URL } from '../utils/constants';
import { BsPencil } from "react-icons/bs";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import { GoPin } from "react-icons/go";
import { BsTrashFill } from "react-icons/bs";
import Jar  from '../components/Jar';
import classnames from 'classnames';
import Wait from '../components/Wait';


class L6 extends React.Component {
  
  state = {
    ownOptions: [],
    selected: [],
    positions: {},
    pleasures: [],
    jarSaving: false
  }

  componentDidMount() {
    let options = JSON.parse(this.props.params.options);
    let selected = [];
    let ownOptions = [];

    try {
      selected = JSON.parse(this.props.answers.data.selected);
      ownOptions = JSON.parse(this.props.answers.data.ownOptions);
    } catch(e) {}

    let positions = {};
    let keys = options.map(el => el.id).concat(ownOptions.map(el => el.id));
    for (var j = 0; j < keys.length; j++) {
      positions[keys[j]] = this.randomPosition();
    }

    let pleasures = this.getPleasures4Jar(selected, ownOptions);

    this.setState({
      selected: selected,
      ownOptions: ownOptions,
      positions: positions,
      pleasures: pleasures
    });
  }

  saveData = () => {
    let data = {
        accessKey: this.props.lessonKey,
        data: {
            selected: JSON.stringify(this.state.selected),
            ownOptions: JSON.stringify(this.state.ownOptions)
        }
    };

    fetch(API_BASE_URL + "/task/save", {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        }
    });
  }

  randomPosition = () => {  

    let position = {};

    let x = Math.floor(Math.random() * 51);
    let y = Math.floor(Math.random() * 51);

    if (Math.floor(Math.random() * 2) === 1) {
      position.left = x + "%";
    } else {
      position.right = x + "%";
    }

    if (Math.floor(Math.random() * 2) === 1) {
      position.top = y + "%";
    } else {
      position.bottom = y + "%";
    }

    position.transform = "rotate(" + (Math.floor(Math.random() * 180) - 90) + "deg)";

    return position;
  }

  toggleInJar = (option) => {
    let selected = this.state.selected;
    let positions = this.state.positions;

    const index = selected.indexOf(option);
    if (index > -1) {
      selected.splice(index, 1);
    } else {      
      selected.push(option);
      positions[option] = this.randomPosition();
    }    

    this.setState({
      selected: selected,
      positions: positions,
      pleasures: this.getPleasures4Jar(selected, this.state.ownOptions)
    }, () => this.saveData());
  }

  getHashCode = (value) => {
    var hash = 0, i, chr;
    for (i = 0; i < value.length; i++) {
      chr   = value.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  getRandomRotate = (val) => {
    let angle = Math.abs(this.getHashCode(val)) % 11 - 5;
    return "rotate(" + angle + "deg)";
  }

  getMinOwnID = () => {
    let v = -1;
    for (let i = 0; i < this.state.ownOptions.length; i++) {
      if (this.state.ownOptions[i].id <= v) {
        v = this.state.ownOptions[i].id - 1;
      }
    }

    return v;
  }



  removeOwnOption = (o) => {
    let ownOptions = this.state.ownOptions;

    ownOptions = ownOptions.filter(el => el.id !== o);
    this.setState({ownOptions: ownOptions}, () => this.saveData());
  }

  changeOwnOption = (o, v) => {
    let ownOptions = this.state.ownOptions;
    let index = ownOptions.map(el => el.id).indexOf(o);
    ownOptions[index].text = v;
    this.setState({
      ownOptions: ownOptions
    }, () => this.saveData());
  }

  saveJarButton = (decision) => {
    this.setState({jarSaving: true});
    fetch(API_BASE_URL + "/task/jargame/" + this.props.lessonKey + "/" + decision, {
        method: 'get'
    })
        .then(res => res.json())
        .then(json => {
            if(json.status === 200) {        
              this.props.getLessonData(()=>{
                this.setState({jarSaving: false});
              });
            } else {
               alert("wystąpił błąd, skontaktuj się z nami by go zgłosić")
            }
        })
        .catch((error) => {
          alert("wystąpił błąd, skontaktuj się z nami by go zgłosić")
        });  
  }

  getPleasures4Jar = (selected, ownOptions) => {
    let p = [];
    for (var i = 0; i < selected.length; i++) {
      let s = selected[i];
      try {
        let option = ownOptions.find(el => el.id === s);
        if (!option) {
          option = JSON.parse(this.props.params.options).find(el => el.id === s);
        }
        
        p.push({
          id: s,
          text: option.text
        });
      } catch(e) {}
    }

    return p;
  }

  render() {
    return (
      <Row className={classnames("exercise-box", styles.exerciseBox6, this.props.className)}>
          <Col className="text-center">
            <h4><BsPencil /> ZADANIE</h4>
            <p className="content">{this.props.params.content}</p>
            <hr/>
            <div className={"text-left mpx-5"}>
                <Row>
                  <Col lg={12} className="text-center">
                    <Jar pleasures={ this.state.pleasures } positions={ this.state.positions } height={"30em"}/>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="p-3 text-center">
                    {
                      this.state.jarSaving
                      ?
                        <Wait  type="waiting-inline"/>
                      :
                      <>
                        <Button className="main mx-2" onClick={() => {
                              let ownOptions = this.state.ownOptions;
                              ownOptions.push({id: this.getMinOwnID(), text: ""});
                              this.setState({
                                ownOptions: ownOptions
                              });
                            }}>Dodaj własną przyjemność</Button>
                        {
                          this.props.jarGameButton
                          ?
                          <Button className="main mx-2" onClick={() => {this.saveJarButton(false)}}>Usuń skrót do słoika</Button>
                          :
                          <Button className="main mx-2" onClick={() => {this.saveJarButton(true)}}>Zapisz skrót do słoika</Button>
                        }
                      </>
                    }
                    
                  </Col>
                </Row>
                <Row className="mt-4">
                  <h5>{this.props.params.title}</h5>
                </Row>
                <Row>
                  {
                    this.state.ownOptions.map(el => el.id).sort((a, b) => {return Math.abs(b) - Math.abs(a)}).map(o => {
                    let option = this.state.ownOptions.find(el => el.id === o);
                      return (
                        <Col lg={12} key={o} className={classnames("my-0 p-1", styles.optionNotice)}>
                          { 
                            this.state.selected.indexOf(o) === -1 
                            ?
                            <>
                              <textarea className={styles.notice} value={option.text} onChange={(e) => { this.changeOwnOption(o, e.target.value)}}></textarea>
                              <MdAddCircle className={styles.add} color={"green"}  size={35} onClick={() => { this.toggleInJar(o) }}/>
                              <BsTrashFill className={styles.trash} color={"red"}  size={35} onClick={() => { this.removeOwnOption(o) }} />
                            </>
                            :
                            <>
                              <div className={classnames(styles.notice, styles.pinned)} >{option.text}</div>
                              <MdRemoveCircle className={styles.remove} color={"red"} size={35} onClick={() => { this.toggleInJar(o) }}/>
                              <GoPin color={"#17a2b8"} className={styles.pin} size={40} />
                            </>
                          }
                        </Col>
                      )
                    })
                  }
                  {
                    JSON.parse(this.props.params.options).map(el => el.id).map(o => {
                    let option = JSON.parse(this.props.params.options).find(el => el.id === o);
                      return (
                        <Col lg={12} key={o} className={classnames("my-0 p-1", styles.optionNotice)}>
                          { 
                            this.state.selected.indexOf(o) === -1 
                            ?
                            <>
                              <div className={styles.notice} >{option.text}</div>
                              <MdAddCircle className={styles.add} color={"green"}  size={35} onClick={() => { this.toggleInJar(o) }}/>
                            </>
                            :
                            <>
                              <div className={classnames(styles.pinned, styles.notice)}>{option.text}</div>
                              <MdRemoveCircle className={styles.remove} color={"red"} size={35} onClick={() => { this.toggleInJar(o) }}/>
                              <GoPin color={"#17a2b8"} className={styles.pin} size={40} />
                            </>
                          }
                        </Col>
                      )
                    })
                  }
                </Row>
            </div>
          </Col>
      </Row>
    );
  }
}

export default L6;