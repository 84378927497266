
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styles from './L5.module.scss';
import { API_BASE_URL } from '../utils/constants';
import { BsPencil } from "react-icons/bs";
import classnames from 'classnames';
import { MdCancel } from "react-icons/md";
import swal from 'sweetalert';


class L5 extends React.Component {
  
  state = {
    submitted: false,
    answers: {}
  }

  reset = (clear) => {
    let answers = {};

    if (!clear) {
      try { 
        answers = JSON.parse(this.props.answers.data.answers);
      } catch (e) {}
    }

    this.setState({
      submitted: Object.keys(answers).length > 0,
      answers: answers
    });
  }

  componentDidMount() {
    this.reset();
  }

  submitForm = () => {
    if (this.state.submitted) {
        return;
    }

    let data = {
        accessKey: this.props.lessonKey,
        data: {
            answers: JSON.stringify(this.state.answers)
        }
    };

    fetch(API_BASE_URL + "/task/save", {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        }
    });

    this.setState({
        submitted: true
    }, () => {
      
      if (this.existsBadAnswer()) {
          swal("", "Spróbuj jeszcze raz. Zaznaczyliśmy na czerwono obszary, które możesz poprawić. Zresetuj ankietę przyciskiem pod nią i spróbuj wykonać ćwiczenie ponownie.", "error");
      } else {
          swal("", "Gratulacje, udało Ci się poprawnie uzupełnić ćwiczenie! Zaproponowane przez Ciebie myśli to właśnie te, które pozwalają nam poczuć się lepiej w trudnej sytuacji. Pamiętaj, że nastawienie, z jakim podchodzimy do danego zagadnienia, wyzwala w nas (lub ogranicza) zasoby do podjęcia działania.", "success");
      }
  });
  }

  existsBadAnswer = () => {
    let value = false;
    Object.keys(this.props.params).filter(p => p.indexOf("question-") === 0)
        .map(o => {
            let j = JSON.parse(this.props.params[o]);
            if (this.state.answers[o] !== j.correct) {
                value = true;
            }
            return null;
        });

    return value;
  }

  getSaveButton = () => {
    if (this.state.submitted) {
      return (
        <>
            {
                this.existsBadAnswer()
                ?
                <span>Spróbuj jeszcze raz. Zaznaczyliśmy na czerwono obszary, które możesz poprawić.</span>
                :
                <span>Gratulacje, udało Ci się poprawnie uzupełnić ćwiczenie! Zaproponowane przez Ciebie myśli to właśnie te, które pozwalają nam poczuć się lepiej w trudnej sytuacji. Pamiętaj, że nastawienie, z jakim podchodzimy do danego zagadnienia, wyzwala w nas (lub ogranicza) zasoby do podjęcia działania.</span>
            }
            <Button onClick={() => {this.reset(true)}} className={"main mt-2"}>
                Resetuj
            </Button>
        </>
      );
    } else {
      return (
        <Button onClick={this.submitForm} className={"main mt-2"}>
            Zapisz odpowiedzi
        </Button>
      )
    }
  }

  toggleAnswer = (question, answer) => {
    if (this.state.submitted) {
      return;
    }
    let answers = this.state.answers;
    answers[question] = answer;
    this.setState({answers: answers});
  }

  isOptionChecked = (question, answer) => {
    return this.state.answers[question] === answer;
  }

  getResult = (q) => {
    if (!this.state.submitted) {
        return null;
    }

    let question = JSON.parse(this.props.params[q]);
    if (this.state.answers[q] !== question.correct) {
      return (
        <span className={styles.badanswer}><MdCancel />Proponowana odpowiedź jest błędna, spróbuj rozwiązać to zadanie raz jeszcze.</span>
      )
    }
  }

  render() {
    return (
      <Row className={classnames("exercise-box", styles.exerciseBox5, this.props.className)}>
          <Col className="text-center">
            <h4><BsPencil /> ZADANIE</h4>
            <p className="content">{this.props.params.content}</p>
            <hr/>
            <div className={"text-left mpx-5"}>
              {
                Object.keys(this.props.params).filter(p => p.indexOf("question-") === 0).sort((a, b) => parseInt(a.replace("question-", "")) - parseInt(b.replace("question-", "")))
                  .map(o => {
                      let j = JSON.parse(this.props.params[o]);
                      return (
                        <div key={o} className="question-box my-3">
                          <h6>{j.question}</h6>
                          {
                            Object.keys(j.answers).map(a => {
                              return (
                                <div key={o + "-" + a} className="checkbox pl-2">
                                      <input 
                                          type={j.type}
                                          id={o + "-" + a} 
                                          name={o}
                                          disabled={this.state.submitted}
                                          onChange={() => this.toggleAnswer(o, a)}
                                          checked = {this.isOptionChecked(o, a) }
                                      />
                                      <label htmlFor={o + "-" + a}>
                                          <span>{j.answers[a]}</span>
                                      </label>
                                  </div>
                              )
                        })
                          }
                      { this.getResult(o) }
                    </div>
                  )
                })
              }
            </div>
            <div className="submit-row">
              { this.getSaveButton() }            
            </div>
          </Col>
      </Row>
    );
  }
}

export default L5;