import React from 'react';
import styles from './L13.module.scss';
import {Row, Col, Button, Table} from 'react-bootstrap';
import { BsPencil } from "react-icons/bs";
import classnames from 'classnames';
import {  MdAddCircle } from "react-icons/md";
import { Tooltip } from 'react-tippy';
import {  ResizableBox } from 'react-resizable';
import Draggable from 'react-draggable';
import { AiOutlineDrag } from "react-icons/ai";
import TimePicker from '../components/TimePicker';
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import ContentEditable from 'react-contenteditable';


class L13 extends React.Component {

    state = {
        showRoles: false,
        rolesAvailable: [],
        competencesAvailable: {},
        myRoles: [],
        edited: {role: null, competences: []},
        finished: false,
        worktable: {
            top: 240,
            height: 270
        },
        selectedThird: [],
        thirdSubmitted: false,
        thirdText: null,
        workArray: [],
        breakArray: [],
        workPlan: {
            workFrom: 8 * 60,
            workTo: 16 * 60
        }
    }

    componentDidMount() {
        let data = localStorage.getItem(this.props.lessonKey + "_exercise13_1");
        if (data) {
            data = JSON.parse(data);
            this.setState({
                rolesAvailable: data.rolesAvailable,
                competencesAvailable: data.competencesAvailable,
                myRoles: data.myRoles,
                finished: data.finished
            })
        } else {
            this.reset();
        }
        let data3 = localStorage.getItem(this.props.lessonKey + "_exercise13_3");
        if (data3) {
            data3 = JSON.parse(data3);
            this.setState({
                selectedThird: data3.selectedThird,
                thirdSubmitted: data3.thirdSubmitted,
                thirdText: data3.thirdText
            });
        }

        let workArray;
        let breakArray;
        let workPlan;
        
        try {
            workArray = JSON.parse(localStorage.getItem(this.props.lessonKey + "_exercise13_wa"));
        } catch(e){}
        if (!workArray || workArray.length === 0) workArray = JSON.parse(this.props.params.workArray);
        
        try {
            breakArray = JSON.parse(localStorage.getItem(this.props.lessonKey + "_exercise13_ba"));
        } catch(e){}
        if (!breakArray || breakArray.length === 0) breakArray = JSON.parse(this.props.params.breakArray);
        
        try {
            workPlan = JSON.parse(localStorage.getItem(this.props.lessonKey + "_exercise13_wp"));
        } catch(e){}
        if (!workPlan || workPlan.length === 0) {
            workPlan = {
                workFrom: 8 * 60,
                workTo: 16 * 60
            }
        }

        this.setState({
            workArray: workArray,
            breakArray: breakArray,
            workPlan: workPlan
        });
    }

    saveToMemory = () => {
        let data = {
            rolesAvailable: this.state.rolesAvailable,
            competencesAvailable: this.state.competencesAvailable,
            myRoles: this.state.myRoles,
            finished: this.state.finished
        }
        localStorage.setItem(this.props.lessonKey + "_exercise13_1", JSON.stringify(data));
        localStorage.setItem(this.props.lessonKey + "_exercise13_wa", JSON.stringify(this.state.workArray));
        localStorage.setItem(this.props.lessonKey + "_exercise13_ba", JSON.stringify(this.state.breakArray));
        localStorage.setItem(this.props.lessonKey + "_exercise13_wp", JSON.stringify(this.state.workPlan));
    }

    reset = () => {
        let rolesAvailable = JSON.parse(this.props.params.roles);
        let competencesAvailable = {};
        for (let i = 0; i < rolesAvailable.length; i++) {
            competencesAvailable[rolesAvailable[i].id] = JSON.parse(this.props.params.competences);
        }
        this.setState({
            showRoles: false,
            rolesAvailable: rolesAvailable,
            competencesAvailable: competencesAvailable,
            myRoles: [],
            edited: {role: null, competences: []},
            finished: false
        });
    }

    addNewRole = () => {
        let rolesAvailable = this.state.rolesAvailable;
        let competencesAvailable = this.state.competencesAvailable;
        let maxID = 1;
        for (let i = 0; i < rolesAvailable.length; i++) {
            if (rolesAvailable[i].id >= maxID) {
                maxID = rolesAvailable[i].id + 1;
            }
        }
        rolesAvailable.push({"id":maxID,"text":""});
        competencesAvailable[maxID] = JSON.parse(this.props.params.competences);
        this.setState({rolesAvailable: rolesAvailable, competencesAvailable: competencesAvailable}, this.saveToMemory);
    }

    getRoleById = (id) => {
        for (let i = 0; i < this.state.rolesAvailable.length; i++) {
            if (this.state.rolesAvailable[i].id === id) {
                return this.state.rolesAvailable[i];
            }
        }
        return null;
    }

    changeRoleText = (id, val) => {
        let rolesAvailable = this.state.rolesAvailable;
        for (let i = 0; i < rolesAvailable.length; i++) {
            if (rolesAvailable[i].id === id) {
                rolesAvailable[i].text = val;
                break;
            }
        }
        this.setState({rolesAvailable: rolesAvailable}, this.saveToMemory);
    }

    checkEditedRole = (role) => {
        let edited = this.state.edited;
        edited.role = role;
        this.setState({edited: edited}, this.saveToMemory);
    }

    addNewCompetence = () => {
        if (!this.state.edited.role) {
            return;
        }

        let competencesAvailable = this.state.competencesAvailable;
        let maxID = 1;
        for (let i = 0; i < competencesAvailable[this.state.edited.role.id].length; i++) {
            if (competencesAvailable[parseInt(this.state.edited.role.id)][i].id >= maxID) {
                maxID = competencesAvailable[parseInt(this.state.edited.role.id)][i].id + 1;
            }
        }
        competencesAvailable[this.state.edited.role.id].push({"id":maxID,"text":""});
        this.setState({competencesAvailable: competencesAvailable}, this.saveToMemory);
    }

    changeCompetenceText = (roleID, competenceID, value) => {
        let competencesAvailable = this.state.competencesAvailable;
        for (let i = 0; i < competencesAvailable[roleID].length; i++) {
            if (competencesAvailable[roleID][i].id === competenceID) {
                competencesAvailable[roleID][i].text = value;
                break;
            }
        }
        this.setState({competencesAvailable: competencesAvailable}, this.saveToMemory);
    }

    checkCompetence = (competence) => {
        if (!this.state.edited.role) {
            return;
        }
        let edited = this.state.edited;
        edited.competences.push(competence);
        this.setState({edited: edited}, this.saveToMemory);
    }

    getAvailableCompetences = (roleID) => {
        let competences = [];
        for (let i = 0; i < this.state.competencesAvailable[roleID].length; i++) {
            let isAdded = false;
            for (let j = 0; j < this.state.edited.competences.length; j++) {
                if (this.state.competencesAvailable[roleID][i].id === this.state.edited.competences[j].id) {
                    isAdded = true;
                    break;
                }
            }
            if (!isAdded) {
                competences.push(this.state.competencesAvailable[roleID][i]);
            }
        }
        return competences;
    }

    saveRole = () => {
        let myRoles = this.state.myRoles;
        myRoles.push(this.state.edited);
        this.setState({myRoles: myRoles, edited: {role: null, competences: []}, showRoles: false}, this.saveToMemory);
    }

    endExercise = () => {
        this.setState({finished: true}, this.saveToMemory);
    }

    
    handleDrag = (e, ui) => {
        let worktable = this.state.worktable;
        worktable.top = worktable.top + ui.deltaY;
        if (this.state.to) {
            clearTimeout(this.state.to);
        }
        let to = setTimeout(() => {
            this.setState({worktable: worktable});
        }, 100);    

        this.setState({to: to});

    }

    onResize = (event, {element, size, handle}) => {
        let worktable = this.state.worktable;
        worktable.width = size.width;
        worktable.height = size.height;
        this.setState({worktable: worktable});
    };

    toggleThird = (o) => {
        let selectedThird = this.state.selectedThird;
        let index = selectedThird.indexOf(o);
        if (index === -1) {
            selectedThird.push(o);
        } else {
            selectedThird.splice(index, 1);
        }
        this.setState({selectedThird: selectedThird});
    }

    saveThird = () => {
        let thirdText = null;
        if (this.state.selectedThird.length < 2) {
            thirdText = this.props.params.text1;
        } else if (this.state.selectedThird.length < 4) {
            thirdText = this.props.params.text2;
        } else if (this.state.selectedThird.length < 7) {
            thirdText = this.props.params.text3;
        } else {
            thirdText = this.props.params.text4;
        }

        this.setState({
            thirdSubmitted: true,
            thirdText: thirdText
        }, () => {
            localStorage.setItem(this.props.lessonKey + "_exercise13_3", JSON.stringify({
                selectedThird: this.state.selectedThird,
                thirdSubmitted: this.state.thirdSubmitted,
                thirdText: this.state.thirdText
            }));
        });
    }

    resetThird = () => {
        this.setState({            
            selectedThird: [],
            thirdSubmitted: false,
            thirdText: null
        });
    }

    getHourClass = (hour) => {
        if (this.state.workPlan.workFrom <= hour * 60 && this.state.workPlan.workTo > hour * 60) {
            return classnames(styles.mbg, styles.row)
        } else {
            return classnames(styles.row);
        }
    }

    handleWorkPlan = (type, value) => {
        let workPlan = this.state.workPlan;
        workPlan[type] = value;
        this.setState({workPlan: workPlan}, this.saveToMemory);
    }

    toggleDetails = (type, id) => {
        let a = this.state[type];
        for (let i = 0; i < a.length; i++) {
            if (a[i].id === id) {
                a[i].showDetails = !a[i].showDetails;
            }
        }
        if (type === "workArray") {
            this.setState({workArray: a}, this.saveToMemory);
        } else if (type === "breakArray") {
            this.setState({breakArray: a}, this.saveToMemory);
        }
    }

    addToArray = (type) => {
        let a = this.state[type];
        let maxID = 1;
        for (let i = 0; i < a.length; i++) {
            if (a[i].id >= maxID) maxID = a[i].id + 1;
        }
        a.push({
            id: maxID,
            text: "Wpisz własny tekst"
        });
        if (type === "workArray") {
            this.setState({workArray: a}, this.saveToMemory);
        } else if (type === "breakArray") {
            this.setState({breakArray: a}, this.saveToMemory);
        }
    }

    handleChangeParam = (type, id, param, v) => {
        let val = v;
        if (v && v.target && v.target.value) {
            val = v.target.value;
        }
        if (val === "on") {
            val = v.target.checked;
        }
        let a = this.state[type];
        for (let i = 0; i < a.length; i++) {
            if (a[i].id === id) {
                a[i][param] = val;
            }
        }
        if (type === "workArray") {
            this.setState({workArray: a}, this.saveToMemory);
        } else if (type === "breakArray") {
            this.setState({breakArray: a}, this.saveToMemory);
        }
    }

    getFromArrayWithID = (array, id) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i].id === id) {
                return array[i];
            }
        }
    }

    getTimeString = (val) => {
      let hour = "00" + Math.floor(val / 60);
      let min = "00" + val % 60;
  
      hour = hour.substring(hour.length - 2);
      min = min.substring(min.length - 2);
      return hour + ":" + min;
    }

    getHourContent = (hour) => {
        var r = [];
        let k = 1;
        this.state.workArray.filter(el => {
            let elFrom = this.getFromArrayWithID(this.state.workArray, el.id).from || this.getDefaultTime("work").from;
            let elTo = this.getFromArrayWithID(this.state.workArray, el.id).to|| this.getDefaultTime("work", elFrom).to;
            return el.checked && (hour * 60 >= elFrom && hour * 60 < elTo) ;
        }).map(el => {
            let elFrom = this.getFromArrayWithID(this.state.workArray, el.id).from || this.getDefaultTime("work").from;
            let elTo = this.getFromArrayWithID(this.state.workArray, el.id).to|| this.getDefaultTime("work", elFrom).to;
            return {type: "work", from: elFrom, to: elTo, text: el.text}
        }).concat(
            this.state.breakArray.filter(el => {
                let elFrom = this.getFromArrayWithID(this.state.breakArray, el.id).from || this.getDefaultTime("break").from;
                let elTo = this.getFromArrayWithID(this.state.breakArray, el.id).to || this.getDefaultTime("break", elFrom).to;
                return el.checked && (hour * 60 >= elFrom && hour * 60 < elTo) ;
            }).map(el => {
                let elFrom = this.getFromArrayWithID(this.state.breakArray, el.id).from || this.getDefaultTime("break").from;
                let elTo = this.getFromArrayWithID(this.state.breakArray, el.id).to || this.getDefaultTime("break", elFrom).to;
                return {type: "break", from: elFrom, to: elTo, text: el.text}
            })
        ).sort((a, b) => {
            let r = a.from - b.from;
            if (r != 0) return r;
            r = a.to - b.to;
            return r;
        }).map(el => {
            r.push(
                <div key={k++} className={classnames(styles.workBlock, styles[el.type])}>
                    <div className={styles.hours}>
                        {this.getTimeString(el.from) + "-" + this.getTimeString(el.to)}
                    </div>
                    <div className={styles.text} dangerouslySetInnerHTML={ { __html: el.text }}>
                        
                    </div>
                </div>
            )
            return null;
        });

        return r;
    }

    getDefaultTime = (type, from, to) => {
        let t = {
            from: from,
            to: to
        }
        let d = type == "work" ? 60 : 5;
        if (isNaN(t.from) && !isNaN(t.to)) t.from = t.to - d;
        if (!isNaN(t.from) && isNaN(t.to)) t.to = t.from + d;
        if (isNaN(t.from)) t.from = this.state.workPlan.workFrom;        
        if (isNaN(t.to)) t.to = t.from + d;
        if (t.from < this.state.workPlan.workFrom) t.from = this.state.workPlan.workFrom;
        if (t.to < this.state.workPlan.workFrom) t.to = t.from + d;
        if (t.from > this.state.workPlan.workTo) t.from = this.state.workPlan.workFrom;
        if (t.to > this.state.workPlan.workTo) t.to = t.from + d;
        if (t.to > this.state.workPlan.workTo) t.to = this.state.workPlan.workTo;

        return t;

    }

    render() {
        let id = 0;
        return (
            <>
            <Row className={classnames("my-3 exercise-box", styles.exerciseBox13, this.props.className)}>
                <Col className="text-center">
                    <h4><BsPencil /> ZADANIE</h4>
                    <p className="content text-justify">{this.props.params.content1}</p>
                    <hr/>
                    <p className="text-justify wspw">{this.props.params.content2}</p>
                    <div className="text-left mt-4">
                        {
                            this.state.myRoles.map(r => {
                                return (
                                    <div key={id++}>
                                        Jestem <b>{r.role.text}</b>. W tej roli wykorzystuję/nabywam: <ul>{
                                            r.competences.map(c => {
                                                return <li key={c.id}>{c.text}</li>
                                            })
                                        }
                                        </ul>
                                    </div>
                                )
                            })
                        }
                        {
                            this.state.finished ? null
                            :
                            <>
                            { this.state.showRoles ? null : <Button className="main small" onClick={()=>{this.setState({showRoles: true})}}>{this.state.myRoles.length === 0 ? "Wybierz rolę" : "Dodaj nową rolę"}</Button> }
                            { !this.state.showRoles ? null : <Button className="main small" onClick={()=>{this.setState({showRoles: false, edited: {role: null, competences: []}})}}>Anuluj</Button> }
                            { this.state.myRoles.length === 0 ? null : <Button className="main small" onClick={this.endExercise}>Zakończ ćwiczenie</Button>}
                            </>
                        }
                        {
                            !this.state.showRoles ? null :
                                <>
                                <h4 className="mt-3">Jestem/chciał(-a)bym być:</h4>
                                {
                                    this.state.edited.role ?
                                    <div className={styles.blockRow}>
                                        <div key={this.state.edited.role.id} className={styles.blockCol}>
                                            <textarea value={this.state.edited.role.text} disabled={true}></textarea>
                                        </div>
                                    </div>
                                    :
                                    <div className={styles.blockRow}>
                                        {
                                            this.state.rolesAvailable.map(r => {
                                                return (
                                                    <div key={r.id} className={styles.blockCol}>
                                                        <textarea value={r.text} onChange={(ev) => {this.changeRoleText(r.id, ev.target.value)}}></textarea>
                                                        <MdAddCircle onClick={()=>{this.checkEditedRole(r)}} size={30}/>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className={styles.blockColNew}>
                                            <div className={styles.mdiv}>
                                                <Tooltip
                                                    title="Dodaj swój pomysł"
                                                    position="top"
                                                    trigger="mouseenter">
                                                    <MdAddCircle onClick={()=>{this.addNewRole()}} size={30}/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                                </>
                        }           
                        {
                            !this.state.edited.role ? null :
                                <>
                                <h4 className="mt-3">W tej roli wykorzystuję/nabywam:</h4>
                                <div className={styles.blockRow}>
                                    {
                                        this.state.edited.competences.map(r => {
                                            return (
                                                <div key={r.id} className={styles.blockCol}>
                                                    <textarea value={r.text} disabled={true}></textarea>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {this.state.edited.competences.length > 0 ? <h5 className="mt-3">Dodaj kompetencje:</h5> : null}                                 
                                <div className={styles.blockRow}>
                                    {
                                        this.getAvailableCompetences(this.state.edited.role.id).map(r => {
                                            return (
                                                <div key={r.id} className={styles.blockCol}>
                                                    <textarea value={r.text} onChange={(ev) => {this.changeCompetenceText(this.state.edited.role.id, r.id, ev.target.value)}}></textarea>
                                                    <MdAddCircle onClick={()=>{this.checkCompetence(r)}} size={30}/>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className={styles.blockColNew}>
                                        <div className={styles.mdiv}>
                                            <Tooltip
                                                title="Dodaj swój pomysł"
                                                position="top"
                                                trigger="mouseenter">
                                                <MdAddCircle onClick={()=>{this.addNewCompetence()}} size={30}/>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.edited.competences.length > 0 ?
                                        <Button className="main small" onClick={this.saveRole}>Zapisz rolę</Button>
                                    : null
                                }
                                </>
                        }                     
                        {
                            this.state.finished ?
                            <>
                                <p className={styles.finished}>{this.props.params.finished}</p>
                                <div className="text-center">
                                    <Button className="main small" onClick={this.reset}>Resetuj</Button>
                                </div>
                            </>
                            :
                            null
                        }
                    </div>
                </Col>
            </Row>
            <Row className={classnames("my-3 exercise-box", styles.exerciseBox13, this.props.className)}>
                <Col className="text-center">
                    <h4><BsPencil /> ZADANIE</h4>
                    <div className="text-left mt-4">
                        <p>{this.props.params.content3}</p>
                        <hr/>
                        <Row className={styles.timetable}>
                            <Col lg={4}>
                                <div className={styles.box1}>
                                    <div className={styles.title1}>
                                        Plan pracy
                                    </div>
                                    <div>
                                        <div className={styles.w50}>
                                            Od: <TimePicker time={this.state.workPlan.workFrom} maxTime={this.state.workPlan.workTo} onChange={(value => this.handleWorkPlan("workFrom", value))} />
                                        </div>
                                        <div className={styles.w50}>
                                            Do: <TimePicker time={this.state.workPlan.workTo} minTime={this.state.workPlan.workFrom} onChange={(value => this.handleWorkPlan("workTo", value))} />
                                        </div>
                                    </div>
                                    <div className={styles.workarea}>
                                        <div className={this.getHourClass(0)}><div className={styles.mhour}>0:00</div><div className={styles.mblock}>{this.getHourContent(0)}</div></div>
                                        <div className={this.getHourClass(1)}><div className={styles.mhour}>1:00</div><div className={styles.mblock}>{this.getHourContent(1)}</div></div>
                                        <div className={this.getHourClass(2)}><div className={styles.mhour}>2:00</div><div className={styles.mblock}>{this.getHourContent(2)}</div></div>
                                        <div className={this.getHourClass(3)}><div className={styles.mhour}>3:00</div><div className={styles.mblock}>{this.getHourContent(3)}</div></div>
                                        <div className={this.getHourClass(4)}><div className={styles.mhour}>4:00</div><div className={styles.mblock}>{this.getHourContent(4)}</div></div>
                                        <div className={this.getHourClass(5)}><div className={styles.mhour}>5:00</div><div className={styles.mblock}>{this.getHourContent(5)}</div></div>
                                        <div className={this.getHourClass(6)}><div className={styles.mhour}>6:00</div><div className={styles.mblock}>{this.getHourContent(6)}</div></div>
                                        <div className={this.getHourClass(7)}><div className={styles.mhour}>7:00</div><div className={styles.mblock}>{this.getHourContent(7)}</div></div>
                                        <div className={this.getHourClass(8)}><div className={styles.mhour}>8:00</div><div className={styles.mblock}>{this.getHourContent(8)}</div></div>
                                        <div className={this.getHourClass(9)}><div className={styles.mhour}>9:00</div><div className={styles.mblock}>{this.getHourContent(9)}</div></div>
                                        <div className={this.getHourClass(10)}><div className={styles.mhour}>10:00</div><div className={styles.mblock}>{this.getHourContent(10)}</div></div>
                                        <div className={this.getHourClass(11)}><div className={styles.mhour}>11:00</div><div className={styles.mblock}>{this.getHourContent(11)}</div></div>
                                        <div className={this.getHourClass(12)}><div className={styles.mhour}>12:00</div><div className={styles.mblock}>{this.getHourContent(12)}</div></div>
                                        <div className={this.getHourClass(13)}><div className={styles.mhour}>13:00</div><div className={styles.mblock}>{this.getHourContent(13)}</div></div>
                                        <div className={this.getHourClass(14)}><div className={styles.mhour}>14:00</div><div className={styles.mblock}>{this.getHourContent(14)}</div></div>
                                        <div className={this.getHourClass(15)}><div className={styles.mhour}>15:00</div><div className={styles.mblock}>{this.getHourContent(15)}</div></div>
                                        <div className={this.getHourClass(16)}><div className={styles.mhour}>16:00</div><div className={styles.mblock}>{this.getHourContent(16)}</div></div>
                                        <div className={this.getHourClass(17)}><div className={styles.mhour}>17:00</div><div className={styles.mblock}>{this.getHourContent(17)}</div></div>
                                        <div className={this.getHourClass(18)}><div className={styles.mhour}>18:00</div><div className={styles.mblock}>{this.getHourContent(18)}</div></div>
                                        <div className={this.getHourClass(19)}><div className={styles.mhour}>19:00</div><div className={styles.mblock}>{this.getHourContent(19)}</div></div>
                                        <div className={this.getHourClass(20)}><div className={styles.mhour}>20:00</div><div className={styles.mblock}>{this.getHourContent(20)}</div></div>
                                        <div className={this.getHourClass(21)}><div className={styles.mhour}>21:00</div><div className={styles.mblock}>{this.getHourContent(21)}</div></div>
                                        <div className={this.getHourClass(22)}><div className={styles.mhour}>22:00</div><div className={styles.mblock}>{this.getHourContent(22)}</div></div>
                                        <div className={this.getHourClass(23)}><div className={styles.mhour}>23:00</div><div className={styles.mblock}>{this.getHourContent(23)}</div> </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className={styles.title1}>
                                    Praca
                                </div>
                                <Table className={styles.workplain}>
                                    <tbody>
                                        {
                                            this.state.workArray.map(el => {
                                                return (
                                                    <React.Fragment key={el.id}>
                                                        <tr className={classnames(styles.noBorderBottom, styles.main)}>
                                                            <td>
                                                                <ContentEditable
                                                                    html={el.text}
                                                                    onChange={(evt) => {this.handleChangeParam("workArray", el.id, "text", evt.target.value)}}
                                                                    />
                                                            </td>
                                                            <td>{el.showDetails ? <AiOutlineCaretUp onClick={()=>this.toggleDetails("workArray", el.id)}/>: <AiOutlineCaretDown onClick={()=>this.toggleDetails("workArray", el.id)}/>}</td>
                                                        </tr>
                                                        {el.showDetails ? 
                                                            <tr className={classnames(styles.noBorderTop, styles.small)}>
                                                                <td className={styles.details} colSpan="2">
                                                                    <div>
                                                                        <input onChange={(ev) => this.handleChangeParam("workArray", el.id, "checked", ev)} id={"W_" + el.id} name={"W_" + el.id} checked={el.checked === true} type="checkbox" style={{margin: "0 4px", verticalAlign: "middle"}}/>
                                                                        <label htmlFor={"W_" + el.id}>Uwzględnij w Planie Pracy</label>
                                                                    </div>
                                                                    <div>
                                                                        <div className={styles.w50} style={{margin: 0}}>
                                                                            Od: <TimePicker time={this.getFromArrayWithID(this.state.workArray, el.id).from || this.getDefaultTime("work").from} minTime={this.state.workPlan.workFrom} maxTime={this.getFromArrayWithID(this.state.workArray, el.id).to || this.state.workPlan.workFrom}  onChange={(value) => this.handleChangeParam("workArray", el.id, "from", value)}  />
                                                                        </div>
                                                                        <div className={styles.w50} style={{margin: 0}}>
                                                                            Do: <TimePicker time={this.getFromArrayWithID(this.state.workArray, el.id).to || this.getDefaultTime("work", this.getFromArrayWithID(this.state.workArray, el.id).from || this.getDefaultTime("work").from).to} minTime={this.getFromArrayWithID(this.state.workArray, el.id).from || this.state.workPlan.workFrom} maxTime={this.state.workPlan.workTo}  onChange={(value) => this.handleChangeParam("workArray", el.id, "to", value)}  />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        : null }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                        <tr>
                                            <td colSpan={2}>
                                                <Button onClick={()=> {this.addToArray("workArray")}} className="main small">Dodaj nowy</Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col lg={4}>
                                <div className={styles.title1}>
                                    Przerwy w pracy
                                </div>
                                <Table className={styles.workplain}>
                                    <tbody>
                                        {
                                            this.state.breakArray.map(el => {
                                                return (
                                                    
                                                    <React.Fragment key={el.id}>
                                                        <tr className={classnames(styles.noBorderBottom, styles.main)}>
                                                            <td>
                                                                <ContentEditable
                                                                    html={el.text}
                                                                    onChange={(evt) => {this.handleChangeParam("breakArray", el.id, "text", evt.target.value)}}
                                                                    />
                                                            </td>
                                                            <td>{el.showDetails ? <AiOutlineCaretUp onClick={()=>this.toggleDetails("breakArray", el.id)}/>: <AiOutlineCaretDown onClick={()=>this.toggleDetails("breakArray", el.id)}/>}</td>
                                                        </tr>
                                                        {el.showDetails ? 
                                                            <tr className={classnames(styles.noBorderTop, styles.small)}>
                                                                <td className={styles.details} colSpan="2">
                                                                    <div>
                                                                        <input onChange={(ev) => this.handleChangeParam("breakArray", el.id, "checked", ev)} id={"B_" + el.id} name={"B_" + el.id} checked={el.checked === true} type="checkbox" style={{margin: "0 4px", verticalAlign: "middle"}}/>
                                                                        <label htmlFor={"B_" + el.id}>Uwzględnij w Planie Pracy</label>
                                                                    </div>
                                                                    <div>
                                                                        <div className={styles.w50} style={{margin: 0}}>
                                                                            Od: <TimePicker time={this.getFromArrayWithID(this.state.breakArray, el.id).from || this.getDefaultTime("break").from} minTime={this.state.workPlan.workFrom} maxTime={this.getFromArrayWithID(this.state.breakArray, el.id).to || this.state.workPlan.workFrom}  onChange={(value) => this.handleChangeParam("breakArray", el.id, "from", value)}  />
                                                                        </div>
                                                                        <div className={styles.w50} style={{margin: 0}}>
                                                                            Do: <TimePicker time={this.getFromArrayWithID(this.state.breakArray, el.id).to || this.getDefaultTime("break", this.getFromArrayWithID(this.state.breakArray, el.id).from || this.getDefaultTime("break").from).to} minTime={this.getFromArrayWithID(this.state.breakArray, el.id).from || this.state.workPlan.workFrom} maxTime={this.state.workPlan.workTo}  onChange={(value) => this.handleChangeParam("breakArray", el.id, "to", value)}  />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        : null }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                        <tr>
                                            <td colSpan={2}>
                                                <Button onClick={()=> {this.addToArray("breakArray")}} className="main small">Dodaj nowy</Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row className={classnames("my-3 exercise-box", styles.exerciseBox13, this.props.className)}>
                <Col className="text-center">
                    <h4><BsPencil /> ZADANIE</h4>
                    <div className="text-left mt-4">
                    <p className="content text-justify">{this.props.params.content4}</p>
                        <hr/>
                    <p className="content text-justify" style={{fontWeight: "bold"}}>{this.props.params.content5}</p>
                    <div className="text-left">
                        {
                            Object.keys(this.props.params).filter(p => p.indexOf("question-") === 0).sort((a, b) => parseInt(a.replace("question-", "")) - parseInt(b.replace("question-", "")))
                                .map(o => {
                                    return (
                                        <div key={o} className={classnames("checkbox pl-2")}>
                                            <input 
                                                type={"checkbox"} 
                                                id={o} 
                                                name={o}
                                                disabled={this.state.thirdSubmitted}
                                                onChange={() => this.toggleThird(o)}
                                                checked = {this.state.selectedThird.indexOf(o) > -1}
                                            />
                                            <label htmlFor={o}>
                                                <span>{this.props.params[o]}</span>
                                            </label>
                                        </div>
                                    )
                                })
                        }
                    </div>
                    {
                        this.state.thirdSubmitted ?
                        <div className="text-center">
                            <p className={classnames(styles.finished, "text-justify")}>{this.state.thirdText}</p>
                            <Button className="main small" onClick={this.resetThird}>Resetuj</Button>
                        </div>
                        :
                        <div className="text-center">
                            <Button className="main small" onClick={this.saveThird}>Zapisz odpowiedzi</Button>
                        </div>
                    }
                    </div>
                </Col>
            </Row>
        </>
        )
    }
}

export default L13;