import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { BsPencil } from "react-icons/bs";
import styles from './L14.module.scss';
import classnames from 'classnames';
import { API_BASE_URL } from '../utils/constants';
import swal from 'sweetalert';

class L14 extends React.Component {

    state = {
        submitted: false,
        answers: {}
    }

    reset = (clear) => {
        let answers = {};

        if (!clear) {
          try { 
            answers = JSON.parse(this.props.answers.data.answers);
          } catch (e) {}
        }
    
        this.setState({
          submitted: Object.keys(answers).length > 0,
          answers: answers
        });
    }

    componentDidMount () {
        this.reset();
    }

    getCheckboxClass = (a) => {
        if (!this.state.submitted) {
            return false;
        }

        let answer = JSON.parse(this.props.params[a]);
        return (this.state.answers[a] ? true : false) === (answer.correct ? true : false) ? "correct" : "bad";
    }

    getOptionChecked = (a) => {
        return this.state.answers[a] ? true : false;
    }

    toggleAnswer = (a) => {
        let answers = this.state.answers;
        answers[a] = !answers[a];
        this.setState({answers: answers});
    }

    getSaveButton = () => {
        if (this.state.submitted) {
            return (
                <Button onClick={() => {this.reset(true);}} className={"main mt-2"}>
                    Resetuj
                </Button>
            );
        } else {
            return (
                <Button onClick={this.submitForm} className={"main mt-2"}>
                    Sprawdź odpowiedzi
                </Button>
            );
        }
    }

    submitForm = () => {
        if (this.state.submitted) {
             return false;
        }

        let data = {
            accessKey: this.props.lessonKey,
            data: {
                answers: JSON.stringify(this.state.answers)
            }
        };
    
        fetch(API_BASE_URL + "/task/save", {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        });
        
        this.setState({submitted: true}, () => {
            swal(this.getAlert().text)
        });
    }

    getAlert = () => {
        if (!this.state.submitted) {
            return {text: null, style: null}
        }
        let existsBadAnswer = false;
        Object.keys(this.props.params).filter(p => p.indexOf("answer-") === 0).map(a => {
            let answer = JSON.parse(this.props.params[a]);
            if ((this.state.answers[a] ? true : false) !== (answer.correct ? true : false)) {
                existsBadAnswer = true;
            }
            return false;
        });
        
        return existsBadAnswer ? {text: this.props.params.badAlert, style: styles.bad} : {text: this.props.params.correctAlert, style: styles.correct};
    }

    render() {
        
        return (
            <Row className={classnames("exercise-box", styles.exerciseBox14, this.props.className)}>
                <Col className="text-center">
                    <h4><BsPencil /> ZADANIE</h4>
                    <p className="content">{this.props.params.content}</p>
                    <div className="text-left pl-5">
                    {
                        Object.keys(this.props.params).filter(p => p.indexOf("answer-") === 0).sort((a, b) => parseInt(a.replace("answer-", "")) - parseInt(b.replace("answer-", "")))
                        .map(a => {
                            let answer = JSON.parse(this.props.params[a]);
                            return (
                                <div key={a} className={classnames("checkbox pl-2", this.getCheckboxClass(a))}>
                                    <input 
                                        type="checkbox"
                                        id={a} 
                                        name={a}
                                        disabled={this.state.submitted}
                                        onChange={() => { this.toggleAnswer(a) }}
                                        checked = { this.getOptionChecked(a) }
                                    />
                                    <label htmlFor={a}>
                                        <span>{answer.text}</span>                                                   
                                    </label>
                                </div>
                            )
                        })
                    }
                    </div>
                    
                    <div className="submit-row">
                        <div className={this.getAlert().style}>
                            { this.getAlert().text }
                        </div>
                        { this.getSaveButton() }           
                    </div>  
                </Col>
            </Row>
        )
    }
}

export default L14;