
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styles from './L10.module.scss';
import { API_BASE_URL } from '../utils/constants';
import { checkObjects } from '../utils/utils';
import { BsPencil } from "react-icons/bs";
import { Tooltip } from 'react-tippy';
import classnames from 'classnames';
import swal from 'sweetalert';
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import ContentEditable from 'react-contenteditable'
import ReactToPrint from 'react-to-print';

class L10 extends React.Component {
  

  state = {
    list1: [],
    list2: []
  }

  componentDidMount() {
    let list1 = [];
    let list2 = [];

    list1 = JSON.parse(this.props.params.list1);
    list2 = JSON.parse(this.props.params.list2);

    try {
      list1 = JSON.parse(this.props.answers.data.list1);
    } catch(e) {}

    try {
      list2 = JSON.parse(this.props.answers.data.list2);
    } catch(e) {}

    this.setState({list1: list1, list2: list2});
  }

  addNewItem = (listName) => {
    let list = this.state[listName];
    let maxID = 1;
    for (let i = 0; i < list.length; i++) {
        if (list[i].id >= maxID) {
            maxID = list[i].id + 1;
        }
    }
    list.push({id: maxID, text: ""});
    if (listName === "list1") {
      this.setState({list1: list}, this.saveData);
    } else if (listName === "list2") {
      this.setState({list2: list}, this.saveData);
    }
  }

  removeItem = (listName, elementID) => {
    let list = this.state[listName];
    let newList = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].id !== elementID) {
        newList.push(list[i]);
      }
    }
    if (listName === "list1") {
      this.setState({list1: newList}, this.saveData);
    } else if (listName === "list2") {
      this.setState({list2: newList}, this.saveData);
    }

  } 

  changeText = (listName, elementID, value) => {
    let list = this.state[listName];
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === elementID) {
        list[i].text = value;
      }
    }
    if (listName === "list1") {
      this.setState({list1: list}, this.saveData);
    } else if (listName === "list2") {
      this.setState({list2: list}, this.saveData);
    }
  }

  addImagePlace = (listName, elementID) => {
    let list = this.state[listName];
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === elementID) {
        list[i].imagePlace = true;
      }
    }
    if (listName === "list1") {
      this.setState({list1: list}, this.saveData);
    } else if (listName === "list2") {
      this.setState({list2: list}, this.saveData);
    }
  }

  saveData = () => {
    let data = {
        accessKey: this.props.lessonKey,
        data: {
            list1: JSON.stringify(this.state.list1),
            list2: JSON.stringify(this.state.list2)
        }
    };

    fetch(API_BASE_URL + "/task/save", {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        }
    });
  }

  render() {
    return (
      <>
      <Row className={classnames("exercise-box", styles.exerciseBox10, this.props.className)}>
          <Col className="text-center">
            <h4><BsPencil /> ZADANIE</h4>
            <p className="content" dangerouslySetInnerHTML={ { __html: this.props.params.content }}></p>
            <hr/>
            <div className={"text-left mpx-5"}>
            <table ref={el => (this.list1Ref = el)} className={classnames("m-4", styles.mtable)}>
                <tbody>
                  <tr>
                    <td>
                      <h6 style={{marginLeft: "-20px", marginRight: "-20px"}}>{this.props.params.list1title}</h6>
                    </td>
                  </tr>
                {
                  this.state.list1.map(e => {
                    return (
                      <React.Fragment key={e.id}>
                      <tr>
                        <td className={styles.element}>
                          <ContentEditable
                            html={e.text} // innerHTML of the editable div
                            className={classnames(styles.ediv, e.text ? null : styles.bordered)}
                            onChange={(evt) => {this.changeText("list1", e.id, evt.target.value)}}
                          />
                            {
                              e.imagePlace ? null :
                              <Tooltip
                                    title= "Dodaj miejsce na obrazek"
                                    position="top"
                                    trigger="mouseenter">
                                    <MdAddCircle className={styles.btn1} color={"green"}  size={25} 
                                      onClick={() => this.addImagePlace("list1", e.id)}/>
                              </Tooltip>
                            }
                            
                            <Tooltip
                                  title= "Usuń aktywność"
                                  position="top"
                                  trigger="mouseenter">
                              <MdRemoveCircle className={styles.btn2} color={"red"}  size={25}
                                onClick={() => this.removeItem("list1", e.id)} />
                            </Tooltip>
                        </td>
                      </tr>
                      {e.imagePlace ?
                        <tr className={styles.imagePlace}><td><div></div></td></tr>
                      : null}
                      </React.Fragment>
                    )
                  })
                }
                </tbody>
              </table>
              <div className="mb-5">
                <Button className={styles.addBtn} onClick={() => {this.addNewItem("list1")}}>
                  <MdAddCircle color={"green"}  size={25}/> Dodaj swoją aktywność
                </Button>
                <ReactToPrint
                  bodyClass={styles.mtable}
                    copyStyles = {true}
                    trigger={() => {
                      // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                      // to the root node of the returned component as it will be overwritten.
                      return <Button  className={styles.addBtn}>Drukuj listę</Button>;
                    }}
                    content={() => this.list1Ref}
                  />
              </div>
              <table ref={el => (this.list2Ref = el)} className={classnames("mt-5 mx-4 mb-4", styles.mtable)}>
                <tbody>
                  <tr>
                    <td>
                      <h6 style={{marginLeft: "-20px", marginRight: "-20px"}}>{this.props.params.list2title}</h6>
                    </td>
                  </tr>
                {
                  this.state.list2.map(e => {
                    return (
                      <React.Fragment key={e.id}>
                      <tr>
                        <td className={styles.element}>
                          <ContentEditable
                            html={e.text} // innerHTML of the editable div
                            className={styles.ediv}
                            onChange={(evt) => {this.changeText("list2", e.id, evt.target.value)}}
                          />
                            {
                              e.imagePlace ? null :
                              <Tooltip
                                    title= "Dodaj miejsce na obrazek"
                                    position="top"
                                    trigger="mouseenter">
                                    <MdAddCircle className={styles.btn1} color={"green"}  size={25} 
                                      onClick={() => this.addImagePlace("list2", e.id)}/>
                              </Tooltip>
                            }
                            
                            <Tooltip
                                  title= "Usuń aktywność"
                                  position="top"
                                  trigger="mouseenter">
                              <MdRemoveCircle className={styles.btn2} color={"red"}  size={25}
                                onClick={() => this.removeItem("list2", e.id)} />
                            </Tooltip>
                        </td>
                      </tr>
                      {e.imagePlace ?
                        <tr className={styles.imagePlace}><td><div></div></td></tr>
                      : null}
                      </React.Fragment>
                    )
                  })
                }
                </tbody>
              </table>
              <div className="mb-5">
                <Button className={styles.addBtn} onClick={() => {this.addNewItem("list2")}}>
                  <MdAddCircle color={"green"}  size={25}/> Dodaj swój sposób
                </Button>
                <ReactToPrint
                  bodyClass={styles.mtable}
                    copyStyles = {true}
                    trigger={() => {
                      // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                      // to the root node of the returned component as it will be overwritten.
                      return <Button  className={styles.addBtn}>Drukuj listę</Button>;
                    }}
                    content={() => this.list2Ref}
                  />
              </div>
            </div>
          </Col>
      </Row>
      </>
    );
  }
}

export default L10;