
import React from 'react';
import classnames from 'classnames';
import Wait from './Wait';
import { Link } from 'react-router-dom';
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";


class Menu extends React.Component {

    state = {
        mobileExpanded: false
    }

  render() {
      if (!this.props.list) {
          return <Wait type="waiting-nested"/> 
      }
    return (
        <>
            <div className="mobile-expander"
                onClick={() => {this.setState({mobileExpanded: !this.state.mobileExpanded})}}>
                {
                    this.state.mobileExpanded
                    ?
                    <>
                    Zwiń kroki <AiOutlineCaretUp />
                    </>
                    :
                    <>
                    Rozwiń kroki <AiOutlineCaretDown />
                    </>
                }
                
            </div>
            <aside className={(!this.state.mobileExpanded ? "hide" : null)}>
                { this.props.list.map(l => {
                    return (
                        <Link 
                            to={"./" + l.access} 
                            onClick={() => this.props.changeStep(l)}
                            key={l.step} >
                            <div 
                                className={classnames("step-block", this.props.activeLessonKey === l.access ? "active" : null)}>
                                <h5>Krok { l.step }</h5>
                                <h6>{ l.title }</h6>
                            </div>
                        </Link>
                    )
                })}
            </aside>
        </>
    );
  }
}

export default Menu;
