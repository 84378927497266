
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './L8.module.scss';
import { BsPencil } from "react-icons/bs";
import classnames from 'classnames';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { API_BASE_URL, WELCOME_PAGE_URL } from '../utils/constants';


class L8 extends React.Component {
  

  render() {
    return (
      <Row className={classnames("exercise-box", styles.exerciseBox8, this.props.className)}>
          <Col className="text-center">
            <h4><BsPencil /> ZADANIE</h4>
            <p className={styles.mspacing} dangerouslySetInnerHTML={ { __html: this.props.params.content }}></p>
            <hr/>
            <p className={classnames("text-justify", styles.mspacing)} dangerouslySetInnerHTML={ { __html: this.props.params.content1 }}></p>
            <AudioPlayer
              src={this.props.params.audiosrc1}
              onPlay={e=> {
                let data = {
                  accessKey: this.props.lessonKey,
                  description: "L8_audio1_play"
                };
                fetch(API_BASE_URL + "/task/event", {
                    method: 'post',
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json",
                    }
                });
              }}
            />
            
            <p className={classnames("text-justify", styles.mspacing)} dangerouslySetInnerHTML={ { __html: this.props.params.content2 }}></p>
          </Col>
      </Row>
    );
  }
}

export default L8;