
import React from 'react';
import { MdPlayCircleOutline, MdPauseCircleOutline, MdGames } from "react-icons/md";
import { Tooltip } from 'react-tippy';
import { GiCardRandom } from "react-icons/gi";
import {isMobile} from 'react-device-detect';



class Toolbox extends React.Component {

  render() {

    
    return (
      <>
      <div className={"toolbox-alert"}>
        <img src="/images/kontakt.svg" onClick={ this.props.showComfortAlert } />
      </div>
      {
        !this.props.panicButton && !this.props.modalGameButton && !this.props.jarGameButton
        ?
        null
        :
        <div className={"toolbox"}>
          {this.props.panicButton ? 
            ( this.props.panicPlay ?
              <Tooltip
                title="Kliknij, aby zatrzymać"
                position="top"
                trigger= { isMobile ? "none" : "mouseenter"}
              >
                <MdPauseCircleOutline size={30} onClick={ () => { this.props.playPanicText(false) } }/>
              </Tooltip>
              :
              <Tooltip
                title="Kliknij, aby odtworzyć"
                position="top"
                trigger= { isMobile ? "none" : "mouseenter"}
              >
                <MdPlayCircleOutline size={30} onClick={ () => { this.props.playPanicText(true) } }/>
              </Tooltip>
            )
          :
          null
          }
          {
            this.props.modalGameButton ? 
            <Tooltip
                title="Kliknij, aby uruchomić grę"
                position="top"
                trigger={ isMobile ? "none" : "mouseenter"}
              >
                <MdGames size={30} onClick={ () => { this.props.setModalGame(true) } }/>
              </Tooltip>
              : null
          }
          {
            this.props.jarGameButton ? 
            <Tooltip
                title="Kliknij, aby wylosować przyjemność"
                position="top"
                trigger={ isMobile ? "none" : "mouseenter"}
              >
                <GiCardRandom size={30} onClick={ () => { this.props.setJarGame(true) } }/>
              </Tooltip>
              : null
          }
        </div>
      }
      </>
    );
  }
}

export default Toolbox;