
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import styles from './L9.module.scss';
import { BsPencil } from "react-icons/bs";
import classnames from 'classnames';
import { SketchPicker } from 'react-color';
import { MdCancel, MdAddCircle } from "react-icons/md";
import { Tooltip } from 'react-tippy';
import TimePicker from '../components/TimePicker';
import Select from 'react-select'
import Creatable from 'react-select/creatable';
import ReactToPrint from 'react-to-print';
import swal from 'sweetalert';



class L9 extends React.Component {

  constructor(props) {
    super(props);
    this.colors = ["#EDBBE8", "#FFCF70", "#8FFF70", "#62D0D0", "#FFE93F", "#B8C6C6"];
    this.days = ["PONIEDZIAŁEK", "WTOREK", "ŚRODA", "CZWARTEK", "PIĄTEK", "SOBOTA", "NIEDZIELA"];
    this.categories = ["Obowiązki domowe", "Czas dla siebie", "Wspólne przyjemności"];
    this.options = JSON.parse(localStorage.getItem(this.props.lessonKey + "_exercise9_options")) || {
      0: ["Wstawianie prania", "Gotowanie śniadania/obiadu/kolacji", "Mycie okien", "Odkurzanie/mycie podłogi", "Ścieranie kurzy",
        "Opiekowanie się roślinami", "Prasowanie", "Porządki w szafach - składanie i układanie ubrań", "Drobne naprawy sprzętów domowych"],
      1: ["Opiekowanie się roślinami", "Oglądanie filmów/seriali", "Wieczorny/poranny spacer", "Granie w gry komputerowe/granie w gry na konsoli",
        "Czytanie książek", "Rysowanie/malowanie", "Domowe SPA", "Uczenie się nowych rzeczy", "Granie na instrumentach", "Ćwiczenia fizyczne", "Losuj przyjemności"],
      2: ["Oglądanie filmów/seriali", "Granie w gry planszowe", "Wspólny spacer", "Opiekowanie się roślinami", "Układanie puzzli", "Masaż",
          "Robienie kolaży", "Wspólne gotowanie", "Zabawa w gry ruchowe", "Wspólne ćwiczenia fizyczne", "Planowanie wspólnych podróży", "Losuj przyjemności"]
    };
    this.state = {
      people: JSON.parse(localStorage.getItem(this.props.lessonKey + "_exercise9_people")) || {
        0: {
          name: null,
          color: this.colors[0],
          pickColor: false
        },
        1: {
          name: "Wspólne",
          color: this.colors[1],
          pickColor: false
        }
      },
      showModal: false,
      addForm: {
        day: null,
        category: null,
        option: null,
        minTime: 720,
        maxTime: 780
      },
      editForm: {
        show: false,
        day: null,
        category: null,
        option: null,
        minTime: null,
        maxTime: null,
        person: null,
        WD: null,
        WD_index: null
      },
      calendar: JSON.parse(localStorage.getItem(this.props.lessonKey + "_exercise9_calendar")) || {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: []
      }
    }
  }

  saveEditForm = () => {
    let C = this.state.calendar;
    C[this.state.editForm.WD][this.state.editForm.WD_index].category = this.state.editForm.category.value;
    C[this.state.editForm.WD][this.state.editForm.WD_index].option = this.state.editForm.option.value;
    C[this.state.editForm.WD][this.state.editForm.WD_index].minTime = this.state.editForm.minTime;
    C[this.state.editForm.WD][this.state.editForm.WD_index].maxTime = this.state.editForm.maxTime;
    C[this.state.editForm.WD][this.state.editForm.WD_index].person = this.state.editForm.person.value;
    this.setState({
      calendar: C
    }, () => {
      localStorage.setItem(this.props.lessonKey + "_exercise9_calendar", JSON.stringify(this.state.calendar));
      this.cancelEditForm();
    });
  }

  removeEditForm = () => {
    let C = this.state.calendar;
    C[this.state.editForm.WD].splice(this.state.editForm.WD_index, 1);
    this.setState({
      calendar: C
    }, () => {
      localStorage.setItem(this.props.lessonKey + "_exercise9_calendar", JSON.stringify(this.state.calendar));
      this.cancelEditForm();
    });
  }

  cancelEditForm = () => {
    this.setState({
      editForm: {
        show: false,
        day: null,
        category: null,
        option: null,
        minTime: null,
        maxTime: null,
        person: null,
        WD: null,
        WD_index: null
      }
    });
  }

  addFormToTable = () => {

    if (!this.state.addForm.option) {
        swal("Wybierz zajęcie przed dodaniem do kalendarza");
        return;
    }
    if (!this.state.addForm.minTime) {
        swal("Wybierz godzinę rozpoczęcia");
        return;
    }
    if (!this.state.addForm.maxTime) {
        swal("Wybierz godzinę zakończenia");
        return;
    }
    if (!this.state.addForm.category) {
        swal("Wybierz rodzaj przed dodaniem do kalendarza");
        return;
    }

    let calendar = this.state.calendar;
    calendar[this.state.addForm.day].push({
      category: this.state.addForm.category.value,
      option: this.state.addForm.option.value,
      minTime: this.state.addForm.minTime,
      maxTime: this.state.addForm.maxTime,
      person: this.state.addForm.person.value
    });


    this.setState({
      calendar: calendar,
      showModal: false,
      addForm: {
        day: null,
        category: null,
        option: null,
        minTime: 720,
        maxTime: 780
      }
    }, () => {
      localStorage.setItem(this.props.lessonKey + "_exercise9_calendar", JSON.stringify(this.state.calendar));
    });
  }

  handleColorChange = (key, color) => {
    let people = this.state.people;
    people[key].color = color.hex;
    this.setState({people: people}, () => {
      localStorage.setItem(this.props.lessonKey + "_exercise9_people", JSON.stringify(this.state.people));
    });
  }

  toggleColorPicker = (key, value) => {
    let people = this.state.people;
    if (value === undefined) {
      value = !people[key].pickColor;
    }
    people[key].pickColor = value;
    this.setState({people: people}, () => {
      localStorage.setItem(this.props.lessonKey + "_exercise9_people", JSON.stringify(this.state.people));
    });
  }

  removePerson = (key) => {
    let C = this.state.calendar;
    for (var i = 0; i < Object.keys(C).length; i++) {
      let k = Object.keys(C)[i];
      for (var j = 0; j < C[k].length; j++) {
        if (C[k][j].person === key) {
          swal("Nie możesz skasować, ponieważ ta osoba ma aktywne zajęcia w kalendarzu!");
          return;
        }
      }
    }
    let people = this.state.people;
    delete people[key];
    this.setState({people: people}, () => {
      localStorage.setItem(this.props.lessonKey + "_exercise9_people", JSON.stringify(this.state.people));
    });
  }

  addPerson = () => {
    let people = this.state.people;
    let maxID = Math.max.apply(null, Object.keys(people));
    if (maxID < 0 || maxID > 100000) {
      maxID = 0;
    }
    maxID++;
    
    people[maxID] = {
      name: "",
      color: this.colors[maxID % this.colors.length],
      pickColor: false
    } 
    this.setState({people: people}, () => {
      localStorage.setItem(this.props.lessonKey + "_exercise9_people", JSON.stringify(this.state.people));
    });
  }
  
  setName = (key, value) => {
    let people = this.state.people;
    people[key].name = value;
    this.setState({people: people}, () => {
      localStorage.setItem(this.props.lessonKey + "_exercise9_people", JSON.stringify(this.state.people));
    });
  }

  showModal = (day) => {
    let addForm = this.state.addForm;
    addForm.day = day;
    this.setState({
      addForm: addForm,
      showModal: true
    });
  }

  arrayToSelect = (arr) => {
    let r = [];
    if (arr) {
      for (var i = 0; i < arr.length; i++) {
        r.push({value: i, label: arr[i]});
      }
    }
    return r;
  }

  handleFormChange = (type, value) => {
    let form = this.state.editForm.show ? this.state.editForm : this.state.addForm;
    if (type === "option" && value.__isNew__) {
      this.options[form.category.value].push(value.label);
      value.value = this.options[form.category.value].indexOf(value.label);
      localStorage.setItem(this.props.lessonKey + "_exercise9_options", JSON.stringify(this.options));
    }
    form[type] = value;
    if (type === "category") {
      form.option = null;
    }
    this.setState({addForm: form});
    
  }

  getTimeString = (val) => {
    let hour = "00" + Math.floor(val / 60);
    let min = "00" + val % 60;

    hour = hour.substring(hour.length - 2);
    min = min.substring(min.length - 2);
    return hour + ":" + min;
  }

  getContrastYIQ = (hexcolor) => {
      hexcolor = hexcolor.replace("#", "");
      var r = parseInt(hexcolor.substr(0,2),16);
      var g = parseInt(hexcolor.substr(2,2),16);
      var b = parseInt(hexcolor.substr(4,2),16);
      var yiq = ((r*299)+(g*587)+(b*114))/1000;
      return (yiq >= 128) ? 'black' : 'white';
  }

  editCalendarOption = (C, WD) => {
    let editForm = this.state.editForm;
    editForm.show = true;
    editForm.category = {value: C.category, label: this.categories[C.category]};
    editForm.option = {value: C.option, label: this.options[C.category][C.option]};
    editForm.minTime = C.minTime;
    editForm.maxTime = C.maxTime;
    editForm.person = {value: C.person, label: this.state.people[C.person].name};
    editForm.day = WD;
    editForm.WD = WD;
    editForm.WD_index = this.state.calendar[WD].indexOf(C);
    this.setState({
      editForm: editForm
    });
  }

  generateCell = (C, WD) => {
    if (!C) {
      return <td></td>;
    }

    return (
      <td style={{color: this.getContrastYIQ(this.state.people[C.person].color), backgroundColor: this.state.people[C.person].color}}>
        <div className={styles.notice} onClick={()=> { this.editCalendarOption(C, WD) }} style={{color: this.getContrastYIQ(this.state.people[C.person].color), backgroundColor: this.state.people[C.person].color}} >
          <div>{this.getTimeString(C.minTime)}-{this.getTimeString(C.maxTime)}</div>
          <div>{this.state.people[C.person].name}</div>
          <div>{this.options[C.category][C.option]}</div>
        </div>
      </td>
    );
  }

  getCalendarRows = () => {
    let maxLength = 0;
    let C = this.state.calendar;
    for (var i = 0; i < Object.keys(C).length; i++) {
      if (C[Object.keys(C)[i]].length > maxLength) {
        maxLength = C[Object.keys(C)[i]].length;
      }
      C[i].sort((a, b) => { return a.minTime - b.minTime !== 0 ? a.minTime - b.minTime : a.maxTime - b.maxTime});
    }

    
    var r = [];
    for (var j = 0; j < maxLength; j++) {
      r.push(<tr className={styles.noborder} key={j}>
        
          { this.generateCell(C[0][j], 0)}  
        
          { this.generateCell(C[1][j], 1)}         
        
          { this.generateCell(C[2][j], 2)}         
        
          { this.generateCell(C[3][j], 3)}         
        
          { this.generateCell(C[4][j], 4)}         
       
          { this.generateCell(C[5][j], 5)}         
       
          { this.generateCell(C[6][j], 6)}         
       
      </tr>);
    }
    return r;
  }

  render() {
    
    return (
      <>
      <Row className={classnames("exercise-box", styles.exerciseBox9, this.props.className)}>
          <Col className="text-center">
            <h4><BsPencil /> ZADANIE</h4>
            <p className={ styles.content }>{this.props.params.content}</p>
            <hr/>
            <p>{this.props.params.intro1}</p>
            <div className="my-3">
              <Button onClick={()=> {this.addPerson()}} className="main small">Dodaj nową osobę</Button>
              <ReactToPrint
              bodyClass={styles.exerciseBox9}
                copyStyles = {true}
                trigger={() => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return <Button className="main small">Drukuj tabelę</Button>;
                }}
                content={() => this.tableRef}
              />
            </div>
            {
              Object.keys(this.state.people).map(k => {
                let personC = this.state.people[k];
                return (
                  <div key={k} className={ styles.person }>
                    <div className={ styles.inside }>
                      <div className={ styles.name }>
                        <input placeholder="Wpisz imię" value={personC.name || ""} onChange={(ev)=>{ this.setName(k, ev.target.value) }}/>
                        <Tooltip
                          title="Usuń"
                          position="top"
                          trigger="mouseenter">
                          <MdCancel onClick={() => { this.removePerson(k) }} />
                        </Tooltip>
                      </div>
                      <div className={ styles.color } 
                        style={{backgroundColor: personC.color}}
                        onClick={()=>{this.toggleColorPicker(k)}}>
                          &nbsp;
                      </div>
                    </div>
                    {
                      personC.pickColor 
                      ?
                      <>
                        <div className={ styles.cover } onClick={()=>{this.toggleColorPicker(k, false)}}/>
                        <SketchPicker color={personC.color} onChange={ (color) => { this.handleColorChange(k, color) } }/>
                      </>
                      :
                      null
                    }
                  </div>
                )
              })
            }
            <div style={{overflow: "auto"}}>
              <Table ref={el => (this.tableRef = el)}  striped bordered hover className="mt-5">
                <thead>
                  <tr>
                    <th>PONIEDZIAŁEK</th>
                    <th>WTOREK</th>
                    <th>ŚRODA</th>
                    <th>CZWARTEK</th>
                    <th>PIĄTEK</th>
                    <th>SOBOTA</th>
                    <th>NIEDZIELA</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.getCalendarRows()
                  }
                  <tr>
                    <td>
                      <Tooltip
                        title="Dodaj"
                        position="top"
                        trigger="mouseenter">
                          <MdAddCircle onClick={()=>{this.showModal(0)}} size={20}/>
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        title="Dodaj"
                        position="top"
                        trigger="mouseenter">
                          <MdAddCircle onClick={()=>{this.showModal(1)}} size={20}/>
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        title="Dodaj"
                        position="top"
                        trigger="mouseenter">
                          <MdAddCircle onClick={()=>{this.showModal(2)}} size={20}/>
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        title="Dodaj"
                        position="top"
                        trigger="mouseenter">
                          <MdAddCircle onClick={()=>{this.showModal(3)}} size={20}/>
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        title="Dodaj"
                        position="top"
                        trigger="mouseenter">
                          <MdAddCircle onClick={()=>{this.showModal(4)}} size={20}/>
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        title="Dodaj"
                        position="top"
                        trigger="mouseenter">
                          <MdAddCircle onClick={()=>{this.showModal(5)}} size={20}/>
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        title="Dodaj"
                        position="top"
                        trigger="mouseenter">
                          <MdAddCircle onClick={()=>{this.showModal(6)}} size={20}/>
                      </Tooltip>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
      </Row>
      {
        this.state.showModal
        ?
          <div className={styles.modal}>
            <div className={styles.container}>
              <Container>
                <Row className="mb-3">
                  <Col lg={12}>
                    <h4>{this.days[this.state.addForm.day]}</h4>
                  </Col>
                </Row>
                <Row>
                  <Col className="py-4 text-right" lg={3} sm={3}>
                    Od godziny
                  </Col>
                  <Col className="text-left" lg={3} sm={3}>
                    <TimePicker time={this.state.addForm.minTime} maxTime={this.state.addForm.maxTime} onChange={(value => this.handleFormChange("minTime", value))} />
                  </Col>
                  <Col className="py-4 text-right" lg={3} sm={3}>
                    Do godziny
                  </Col>
                  <Col className="text-left" lg={3} sm={3}>
                    <TimePicker time={this.state.addForm.maxTime} minTime={this.state.addForm.minTime} onChange={(value => this.handleFormChange("maxTime", value))} />
                  </Col>
                </Row>
                <Row className="my-3 text-center">
                  <Col className="my-1" lg={12}>
                    <Select placeholder="Wybierz osobę" value={this.state.addForm.person} onChange={(val) => this.handleFormChange("person", val)} options={Object.keys(this.state.people).map(k => {return {value: k, label: this.state.people[k].name}})} />
                    </Col>
                  <Col className="my-1" lg={12}>
                    <Select placeholder="Wybierz kategorię" value={this.state.addForm.category} onChange={(val) => this.handleFormChange("category", val)} options={this.arrayToSelect(this.categories)} />
                  </Col>
                  <Col className="my-1" lg={12}>
                    <Creatable placeholder="Wybierz zajęcie lub wpisz własne" value={this.state.addForm.option} onChange={(val) => this.handleFormChange("option", val)} options={this.arrayToSelect(this.state.addForm.category ? this.options[this.state.addForm.category.value] : null)} />
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col lg={12}>
                    <Button onClick={()=>{this.setState({showModal: false})}} className="main">Anuluj</Button>
                    <Button onClick={() => {this.addFormToTable() }} className="main">Zapisz</Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        :
        null
      }
      
      {
        this.state.editForm.show
        ?
          <div className={styles.modal}>
            <div className={styles.container}>
              <Container>
                <Row className="mb-3">
                  <Col lg={12}>
                    <h4>{this.days[this.state.editForm.day]}</h4>
                  </Col>
                </Row>
                <Row>
                  <Col className="py-4 text-right" lg={3}>
                    Od godziny
                  </Col>
                  <Col className="text-left" lg={3}>
                    <TimePicker time={this.state.editForm.minTime} maxTime={this.state.editForm.maxTime} onChange={(value => this.handleFormChange("minTime", value))} />
                  </Col>
                  <Col className="py-4 text-right" lg={3}>
                    Do godziny
                  </Col>
                  <Col className="text-left" lg={3}>
                    <TimePicker time={this.state.editForm.maxTime} minTime={this.state.editForm.minTime} onChange={(value => this.handleFormChange("maxTime", value))} />
                  </Col>
                </Row>
                <Row className="my-3 text-center">
                  <Col className="my-1" lg={12}>
                    <Select placeholder="Wybierz osobę" 
                      value={this.state.editForm.person} 
                      onChange={(val) => this.handleFormChange("person", val)} 
                      options={Object.keys(this.state.people).map(k => {return {value: k, label: this.state.people[k].name}})} />
                    </Col>
                  <Col className="my-1" lg={12}>
                    <Select placeholder="Wybierz kategorię" value={this.state.editForm.category} onChange={(val) => this.handleFormChange("category", val)} options={this.arrayToSelect(this.categories)} />
                  </Col>
                  <Col className="my-1" lg={12}>
                    <Creatable placeholder="Wybierz zajęcie lub wpisz własne" value={this.state.editForm.option} onChange={(val) => this.handleFormChange("option", val)} options={this.arrayToSelect(this.state.editForm.category ? this.options[this.state.editForm.category.value] : null)} />
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col lg={12}>
                    <Button onClick={()=>{this.cancelEditForm()}} className="main">Anuluj</Button>
                    <Button onClick={()=>{this.removeEditForm()}} className="main">Usuń</Button>
                    <Button onClick={()=>{this.saveEditForm()}} className="main">Zapisz</Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        :
        null
      }
      
      </>
    );
  }
}

export default L9;