
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';
import styles from './L2.module.scss';
import { API_BASE_URL } from '../utils/constants';
import { BsPencil, BsFillInfoCircleFill } from "react-icons/bs";
import { Tooltip } from 'react-tippy';
import swal from 'sweetalert';

class L2 extends React.Component {
    
    state = {
        submitted: false,
        answers: {}
      }
    
      reset = (clear) => {
        let answers = {};
    
        Object.keys(this.props.params).filter(p => p.indexOf("question-") === 0).map(q => {
          answers[q] = [];
          return null;
        });
    
        if (!clear) {
          try { 
            answers = JSON.parse(this.props.answers.data.answers);
          } catch (e) {}
        }
    
        this.setState({
          submitted: Object.keys(answers).filter(a => answers[a].length > 0).length > 0,
          answers: answers
        });
      }
    
      componentDidMount() {
        this.reset();
      }
    
      submitForm = () => {
        if (this.state.submitted) {
            return;
        }
    
        let data = {
            accessKey: this.props.lessonKey,
            data: {
                answers: JSON.stringify(this.state.answers)
            }
        };
    
        fetch(API_BASE_URL + "/task/save", {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        });
    
        this.setState({
            submitted: true
        }, () => {
            let existsBadAnswer = false;
            let questions = Object.keys(this.props.params).filter(p => p.indexOf("question-") === 0);
            for (let i = 0; i < questions.length; i++) {
                if (!this.getResult(questions[i], "bool")) {
                    existsBadAnswer = true;
                    break;
                }
            }

            if (existsBadAnswer) {
                swal("", "Spróbuj jeszcze raz. Zaznaczyliśmy na czerwono obszary, które możesz poprawić. Zresetuj ankietę przyciskiem pod nią i spróbuj wykonać ćwiczenie ponownie.", "error");
            } else {
                swal("", "Gratulacje, udało Ci się poprawnie uzupełnić ćwiczenie!", "success");
            }
        });
      }



    getSaveButton = () => {
        if (Object.keys(this.state.answers).filter(k => !this.state.answers[k] || this.state.answers[k].length === 0).length > 0) {
            return (
                <Tooltip
                    title="Odpowiedz na wszystkie pytania aby sprawdzić odpowiedzi"
                    position="top"
                    trigger="click"
                >
                    <Button className={"main mt-2"}>
                        Sprawdź odpowiedzi
                    </Button>
                </Tooltip>
            );
        } else if (this.state.submitted) {
            return (
                <Button onClick={() => {this.reset(true);}} className={"main mt-2"}>
                    Resetuj
                </Button>
            );
        } else {
            return (
                <Button onClick={this.submitForm} className={"main mt-2"}>
                    Sprawdź odpowiedzi
                </Button>
            );
        }
    }

    getOptionChecked = (o, a) => {
        if (!this.state.answers[o]) {
            return false;
        }
        if (this.state.answers[o].indexOf(a) === -1) {
            return false;
        }

        return true;
    }

    getResult = (question, type) => {
        if (!this.state.submitted) {
            return null;
        }


        var r = {
            "classname" : [styles.bad, styles.correct],
            "alert": [<div className={styles.alert}>Proponowana odpowiedź jest błędna, spróbuj rozwiązać to zadanie raz jeszcze.</div>, <div className={styles.alertCorrect}>Gratulacje, to prawidłowa odpowiedź!</div>],
            "bool": [false, true],
            "classCheckbox": ["bad", "correct"]
        }

        for (let i = 0; i < this.state.answers[question].length; i++) {
            if (JSON.parse(this.props.params[question]).correct.indexOf(this.state.answers[question][i]) === -1) {
                return r[type][0];
            }
        }

        if (this.state.answers[question].length === 0) {
            return r[type][0];
        }
        return r[type][1];
    }
    
    checkAnswer = (question, answer) => {
        let answers = this.state.answers;
        answers[question] = answers[question] || [];

        const index = answers[question].indexOf(answer);
        if (index > -1) {
            answers[question].splice(index, 1);
        } else {
            answers[question].push(answer);
        }

        this.setState({
            answers: answers
        });
    }

    getCheckboxClass = (correct, answer) => {
        if (!this.state.submitted) {
          return null;
        }
    
        return correct.indexOf(answer) > -1 ? "correct" : "bad";
      }

  render() {
    return (
      <Row className={classnames("exercise-box", styles.exerciseBox2, this.props.className)}>
          <Col className="text-center">
            <h4><BsPencil /> ZADANIE</h4>
            <p className="content">{this.props.params.content}</p>
            <hr/>
            <div className={"text-left mpx-5"}>
                {
                    Object.keys(this.props.params).filter(p => p.indexOf("question-") === 0).sort((a, b) => parseInt(a.replace("question-", "")) - parseInt(b.replace("question-", "")))
                        .map(o => {
                            let question = JSON.parse(this.props.params[o]);
                            return (
                                <div key={o} className={classnames("my-3", this.getResult(o, "classname"))}>
                                    <h6 className="text-justify" ><span dangerouslySetInnerHTML={ { __html: question.question }}></span>
                                    {
                                        question.tooltip
                                        ?
                                        <span className={styles.hint}>
                                            <Tooltip
                                                title={question.tooltip}
                                                position="top"
                                                trigger="mouseenter"
                                            >
                                                <BsFillInfoCircleFill size={15}/>
                                            </Tooltip>
                                        </span>
                                        :
                                        null
                                    } 
                                    </h6>
                                                                
                                    {
                                        Object.keys(question.answers).map(a => {
                                            return (
                                                <div key={o + "-" + a} className={classnames("checkbox pl-2", this.getCheckboxClass(question.correct, a))}>
                                                    <input 
                                                        type="checkbox"
                                                        id={o + "-" + a} 
                                                        name={o}
                                                        disabled={this.state.submitted}
                                                        onChange={() => { this.checkAnswer(o, a) }}
                                                        checked = { this.getOptionChecked(o, a) }
                                                    />
                                                    <label htmlFor={o + "-" + a}>
                                                        <span>{question.answers[a].text}</span>                                                   
                                                    </label>
                                                    {
                                                        question.answers[a].tooltip ?
                                                            <span className={styles.hint}>
                                                                <Tooltip
                                                                    title={question.answers[a].tooltip}
                                                                    position="top"
                                                                    trigger="mouseenter"
                                                                >
                                                                    <BsFillInfoCircleFill size={15}/>
                                                                </Tooltip>
                                                            </span>
                                                        :
                                                        null
                                                    } 
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        this.getResult(o, "alert")
                                    }
                                </div>
                            )
                        })
                }

                <div className="submit-row">
                    { this.getSaveButton() }           
                </div>   
            </div>
          </Col>
      </Row>
    );
  }
}

export default L2;