
import React from 'react';
import Wait from './Wait';
import Container from 'react-bootstrap/Container';
import { API_BASE_URL } from '../utils/constants';
import IdleTimer from 'react-idle-timer'
import L1 from '../exercises/L1';
import L2 from '../exercises/L2';
import L3 from '../exercises/L3';
import L4 from '../exercises/L4';
import L5 from '../exercises/L5';
import L6 from '../exercises/L6';
import L7 from '../exercises/L7';
import L8 from '../exercises/L8';
import L9 from '../exercises/L9';
import L10 from '../exercises/L10';
import L11 from '../exercises/L11';
import L12 from '../exercises/L12';
import L13 from '../exercises/L13';
import L14 from '../exercises/L14';
import L15 from '../exercises/L15';
import L16 from '../exercises/L16';
import L17 from '../exercises/L17';
import Form from './Form';
import Extension from './Extension';
import Extension1 from '../extensions/Extension1';
import FullVersion from './FullVersion';
 

class Lesson extends React.Component {

    constructor(props) {
        super(props)
        this.idleTimer = null
        this.onAction = this._onAction.bind(this);
    }

    state = {
        logID: null,
        ignoreFirstOnAction: true,
        extension: null,
        section: null
    };

    _onAction(e) {
        if (!this.props.lesson.logID) {
            return;
        }
        if (this.state.ignoreFirstOnAction) {
          this.setState({
            ignoreFirstOnAction: false
          });
          return;
        }
    
        fetch(API_BASE_URL + "/log/" + this.props.lesson.logID, {method: 'post'});
      }

    openExtension = (p) => {
        this.setState({ extension: p.id, section: p.section });
    }
    
    getExtensionParams = (s) => {
        let params = {
            id: null,
            section: null,
            step: null
        }

        if (s.indexOf(" id=") > -1) {
            let c = s.substring(s.indexOf(" id=") + 4);
            let last = c.indexOf(" ");
            if (last === -1) last = c.indexOf("]");
            params.id = c.substring(0, last);
        }

        if (s.indexOf(" section=") > -1) {
            let c = s.substring(s.indexOf(" section=") + 9);
            let last = c.indexOf(" ");
            if (last === -1) last = c.indexOf("]");
            params.section = c.substring(0, last);
        }
        
        if (s.indexOf(" step=") > -1) {
            let c = s.substring(s.indexOf(" step=") + 6);
            let last = c.indexOf(" ");
            if (last === -1) last = c.indexOf("]");
            params.step = c.substring(0, last);
        }
        return params;
    }

    getFormattedContent = (content, key) => {
        if (!key) {
            key = 1;
        }

        let r = [];
        let firstIndex = content.indexOf("[extension ");
        let lastIndex = content.indexOf("[/extension]");
        if (firstIndex > -1 && lastIndex > -1) {
            let cutted = content.substring(firstIndex, lastIndex);
            let extension = cutted.substring(0, cutted.indexOf("]") + 1);
            cutted = cutted.replace(extension, "");
            r.push(<span key={key++} dangerouslySetInnerHTML={ { __html: content.substring(0, firstIndex) }}></span>);
            let p = this.getExtensionParams(extension);
            if (p.step) {
                // eslint-disable-next-line
                r.push(<a target="_blank" key={key++} className={"extension-link"} href={"/" + this.props.list.filter(el => el.step == p.step)[0].access}>{cutted}</a>);
            } else {
                r.push(<span key={key++} className={"extension-link"} onClick={() => { this.openExtension(p) }}>{cutted}</span>);
            }
            
            r.push(this.getFormattedContent(content.substring(lastIndex + 12), key++));
        } else {
            r.push(<span key={key++} dangerouslySetInnerHTML={ { __html: content }}></span>);
        }
        return r;
    }

  render() {
    if (!this.props.lesson) {
        return (
            <Wait type="waiting-nested"/>
            );
    }

    return (
        <>
            {
                this.state.extension
                ?
                <Extension 
                    id = { this.state.extension } 
                    section = { this.state.section } 
                    lessonKey={this.props.lessonKey} 
                    onClose = { () => { this.setState({ extension: null, section: null }) }} 
                    list = { this.props.list }/>
                :
                null
            }
            <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onAction={this.onAction}
            throttle={30000}/>
            <Container className="py-5 article">
                <h1 className="pb-3">{ this.props.lesson.title }</h1>
                { this.props.lesson.content.map(el => {
                    switch(el.type) {
                        case "paragraph":
                            return (
                                <p key={ el.id } className={ el.classname } >
                                    { this.getFormattedContent(el.value) }
                                </p>
                            )
                        case "blockquote":
                            return (
                                <blockquote key={ el.id } className={ el.classname }>
                                    { this.getFormattedContent(el.value) }
                                </blockquote>
                            )
                        case "image":
                            return (
                                <div key={ el.id } className={ el.classname }><img alt="img" src={ el.value }/></div>
                            )
                        case "fullVersion":
                            return (
                                <FullVersion key={ el.id } className={ el.classname } logID = { this.props.lesson.logID }/>
                            )
                        case "form":
                            if (this.props.accountType === "support" || this.props.accountType === "individual") {
                                return (
                                    <Form step={ this.props.lesson.step } key={ el.id } className={ el.classname } lessonKey={this.props.lessonKey} isFilled={this.props.lesson.formIsFilled}></Form>
                                )
                            } else {
                                return null;
                            }
                        case "extension1":
                            return (
                                <Extension1 key={ el.id } className={ el.classname }/>
                            )
                        case "exercise1":
                            return (
                                <L1 className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        case "exercise2":
                            return (
                                <L2 className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        case "exercise3":
                            return (
                                <L3 className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} 
                                panicButton={ this.props.panicButton } playPanicText={ this.props.playPanicText } panicPlay={ this.props.panicPlay }
                                setModalGame = {this.props.setModalGame} modalGameButton = {this.props.modalGameButton}
                                getLessonData = {this.props.getLessonData}/>
                            )
                        case "exercise4":
                            return (
                                <L4 className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        case "exercise5":
                            return (
                                <L5 className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        case "exercise6":
                            return (
                                <L6 getLessonData = {this.props.getLessonData} className={ el.classname } jarGameButton={this.props.jarGameButton} key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        case "exercise7":
                            return (
                                <L7 className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        case "exercise8":
                            return (
                                <L8 className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        case "exercise9":
                            return (
                                <L9 className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        case "exercise10":
                            return (
                                <L10 className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        case "exercise11":
                            return (
                                <L11 className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        case "exercise12":
                            return (
                                <L12 className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        case "exercise13":
                            return (
                                <L13 className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        case "exercise14":
                            return (
                                <L14 className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        case "exercise15":
                            return (
                                <L15 className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        case "exercise16":
                            return (
                                <L16 className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        case "exercise17":
                            return (
                                <L17 accountType={this.props.accountType} className={ el.classname } key={ el.id } params={ this.props.lesson.params } lessonKey={this.props.lessonKey} answers={this.props.lesson.answers} />
                            )
                        default:
                            return null;
                    }
                })}
            </Container>
        </>
    )
  }
}

export default Lesson;