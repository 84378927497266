import React from 'react';
import styles from './timepicker.module.scss';
import { FaSortUp, FaSortDown } from "react-icons/fa";


class TimePicker extends React.Component {

    state = {
        hour: this.props.hour || 0,
        min: this.props.min || 0
    }

    componentDidMount () {
        if (this.props.time) {
            let h = this.getFromTime(this.props.time).hour;
            let m = this.getFromTime(this.props.time).min;
            this.setState({
                hour: h,
                min: m
            });
        }
    }

    getTime = (hour, min) => {
        return (hour || 0) * 60 + (min || 0);
    }

    getFromTime = (time) => {

        let min = time % 60;
        let hour = (time - min) / 60;

        return {
            hour: hour,
            min: min
        };
    }

    setTime = (hour, min) => {
        if (hour === undefined) hour = this.state.hour;
        if (min === undefined) min = this.state.min;
        
        if (this.props.maxTime !== undefined && this.props.maxTime < this.getTime(hour, min)) {
            hour = this.getFromTime(this.props.maxTime).hour;
            min = this.getFromTime(this.props.maxTime).min;
        }
        
        if (this.props.minTime !== undefined && this.props.minTime > this.getTime(hour, min)) {
            hour = this.getFromTime(this.props.minTime).hour;
            min = this.getFromTime(this.props.minTime).min;
        }

        let h = this.state.hour;
        let m = this.state.min;
        if (hour !== undefined) h = hour;
        if (min !== undefined) m = min;

        this.setState({
            hour: h,
            min: m
        }, () => {
            this.props.onChange(this.getTime(this.state.hour, this.state.min));
        });
    }

    setHours = (value) => {
        value = value.toString();
        let first = value.substring(0, 1);
        value = value.replace( /^\D+/g, '');

        if (value === "") {
            this.setTime(value);
            return;
        }

        if (first === "-") {
            value = "-" + value;
        }
        value = parseInt(value);
        if (value < 0 || value > 23) {
            return;
        }
        this.setTime(value);
    }

    setMinutes = (value) => {
        value = value.toString();

        let first = value.substring(0, 1);
        value = value.replace( /^\D+/g, '');
        if (first === "-") {
            value = "-" + value;
        }
        let hour = this.state.hour;
        let min = this.state.min;

        value = value || "";

        if (value === "") {
            this.setTime(null, value);
            return;
        }

        if (parseInt(value) === -1) {
            if (hour > 0) {
                hour--;
            }
            value = 59;
        } else if (parseInt(value) === 60) {
            if (hour < 23) {
                hour++;
            }
            value = 0;
        } else if (parseInt(value) > 60) {
            return;
        }
        min = parseInt(value);
        
        this.setTime(parseInt(hour), parseInt(min));
    }

    render() {
        return (
            <div className={ styles.container }>
                <div className={ styles.row }>
                    <div className={ styles.col }>
                        <FaSortUp onClick={() => { this.setHours(this.state.hour + 1)}} />
                    </div>
                    <div className={ styles.col }>

                    </div>
                    <div className={ styles.col }>
                        <FaSortUp onClick={() => { this.setMinutes(this.state.min + 1)}} />
                    </div>
                </div>
                <div className={ styles.row }>
                    <div className={ styles.col }>
                        <input placeholder={"godz"} value={this.state.hour} onChange={(ev) => { this.setHours(ev.target.value)}}/>
                    </div>
                    <div className={ styles.col }>
                        :
                    </div>
                    <div className={ styles.col }>
                        <input placeholder={"min"} value={this.state.min} onChange={(ev) => { this.setMinutes(ev.target.value)}}/>
                    </div>
                </div>
                <div className={ styles.row }>
                    <div className={ styles.col }>
                        <FaSortDown onClick={() => { this.setHours(this.state.hour - 1)}} />
                    </div>
                    <div className={ styles.col }>

                    </div>
                    <div className={ styles.col }>
                        <FaSortDown onClick={() => { this.setMinutes(this.state.min - 1)}} />
                    </div>
                </div>
            </div>
        )
    }
}

export default TimePicker;