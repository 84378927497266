import React from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import { BsPencil } from "react-icons/bs";
import classnames from 'classnames';
import styles from './L15.module.scss';
import swal from 'sweetalert';
import { API_BASE_URL } from '../utils/constants';

class L15 extends React.Component {
    state = {
        questions: {}
    }

    componentDidMount () {
        this.reset();
    }

    reset = (clear) => {
        let questions = {};
        Object.keys(this.props.params).filter(p => p.indexOf("question-") === 0)
            .map(q => {
                questions[q] = {
                    status: "inited",
                    answers: {}
                };
                let j = JSON.parse(this.props.params[q]);
                Object.keys(j.answers).map(ak => {
                    questions[q].answers[ak] = {
                        status: "inited",
                        correct: j.correct === ak
                    };
                    return null;
                });
                return null;
            });
            
        if (!clear) {
            try { 
                questions = JSON.parse(this.props.answers.data.questions);
            } catch (e) {}             
        }

        this.setState({
            questions: questions
        });
    }

    toggleAnswer = (q, a) => {
        let questions = this.state.questions;
        if (questions.status === "good") {
            return false;
        }

        let k = Object.keys(questions[q].answers);
        for (let i = 0; i < k.length; i++) {
            if (questions[q].answers[k[i]].status === "checked" ) {
                questions[q].answers[k[i]].status = "inited";
            }
        }
        if (questions[q].answers[a].status !== "inited") {
            return false;
        }

        questions[q].answers[a].status = "checked";
        this.setState({questions: questions}, () => {
        });
        
    }

    isOptionChecked = (q, a) => {
        return ["checked", "correct", "bad"].indexOf(this.state.questions[q].answers[a].status) > -1 ? true : false;
    }

    getSaveButton = () => {
        
        let allQuestionsIsCorrect = true;
        let qk = Object.keys(this.state.questions);
        for (let i = 0; i < qk.length; i++) {
            if (this.state.questions[qk[i]].status !== "correct") {
                allQuestionsIsCorrect = false;
            }
        }

        if (allQuestionsIsCorrect) {
            return (
                <Button onClick={() => {this.reset(true)}} className={"main mt-2"}>
                    Resetuj
                </Button>
            )
        } else {
            return (
                <Button onClick={this.submitForm} className={"main mt-2"}>
                    Sprawdź
                </Button>
            )
        }
    }

    submitForm = () => {
        let answersForAllQuestions = true;
        let qk = Object.keys(this.state.questions);
        for (let i = 0; i < qk.length; i++) {
            let isAnswerForQuestion = false;
            let ak = Object.keys(this.state.questions[qk[i]].answers);
            for (let j = 0; j < ak.length; j++) {
                if (["checked", "correct"].indexOf(this.state.questions[qk[i]].answers[ak[j]].status) > -1) {
                    isAnswerForQuestion = true;
                }
            }
            if (!isAnswerForQuestion) {
                answersForAllQuestions = false;
            }
        }
        if (!answersForAllQuestions) {
            swal("Odpowiedz na wszystkie pytania aby sprawdzić odpowiedzi");
            return false;
        }

        let questions = this.state.questions;
        let alertMsg = "alertFirstTry";
        for (let i = 0; i < qk.length; i++) {
            let ak = Object.keys(this.state.questions[qk[i]].answers);
            for (let j = 0; j < ak.length; j++) {
                if (this.state.questions[qk[i]].answers[ak[j]].status === "checked") {
                    if (this.state.questions[qk[i]].answers[ak[j]].correct) {
                        questions[qk[i]].status = "correct";
                        questions[qk[i]].answers[ak[j]].status = "correct";
                    } else {
                        questions[qk[i]].status = "bad";
                        questions[qk[i]].answers[ak[j]].status = "bad";
                        alertMsg = "alertNotGood";
                    }
                } else if (questions[qk[i]].answers[ak[j]].status === "bad" && alertMsg === "alertFirstTry") {
                    alertMsg = "alertSecondTry";
                }
            }
        }

        if (alertMsg === "alertNotGood") {
            swal("", this.props.params[alertMsg], "error");
        } else {
            swal("", this.props.params[alertMsg], "success");
        }

        this.setState({questions: questions});

        let data = {
            accessKey: this.props.lessonKey,
            data: {
                questions: JSON.stringify(this.state.questions)
            }
        };
    
        fetch(API_BASE_URL + "/task/save", {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        });
    }

    render () {
        return (
            <Row className={classnames("exercise-box", styles.exerciseBox15, this.props.className)}>
                <Col className="text-center">
                    <h4><BsPencil /> ZADANIE</h4>
                    <p className="content">{this.props.params.content}</p>
                    <hr/>
                    <div className={classnames("text-left mpx-5")}>
                        {
                            Object.keys(this.state.questions).map(q => {
                                let j = JSON.parse(this.props.params[q]);
                                return (
                                    <div key={q} className={ classnames("question-box my-3", styles[this.state.questions[q].status])}>
                                        <h6>{j.question}</h6>
                                        {
                                            Object.keys(this.state.questions[q].answers).map(a => {
                                                return (
                                                    <div key={q + "-" + a} className={ classnames("checkbox pl-2", this.state.questions[q].answers[a].status) }>
                                                        <input 
                                                            type={"checkbox"}
                                                            id={q + "-" + a} 
                                                            name={q + "-" + a} 
                                                            disabled={this.state.questions[q].status === "correct"}
                                                            onChange={() => this.toggleAnswer(q, a)}
                                                            checked = { this.isOptionChecked(q, a) }
                                                        />
                                                        <label htmlFor={q + "-" + a}>
                                                            <span>{j.answers[a]}</span>
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="submit-row">
                        { this.getSaveButton() }            
                    </div>
                </Col>
            </Row>
        )
    }
}

export default L15;