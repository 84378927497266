import React from 'react';
import {Row, Col} from 'react-bootstrap';
import classnames from 'classnames';
import styles from './L16.module.scss';
import { API_BASE_URL } from '../utils/constants';

class L16 extends React.Component {
    state = {
        selected: []
    }

    componentDidMount() {
        let selected = [];
        try { 
            selected = JSON.parse(this.props.answers.data.selected);
        } catch (e) {}   
        this.setState({
            selected: selected
        })
    }

    isOptionChecked = (a) => {
        return this.state.selected.indexOf(a) > -1;
    }

    toggleAnswer = (a) => {
        let selected = this.state.selected;
        let index = selected.indexOf(a);
        if (index === -1) {
            selected.push(a);
        } else {
            selected.splice(index, 1);
        }
        this.setState({selected: selected}, () => {
            let data = {
                accessKey: this.props.lessonKey,
                data: {
                    selected: JSON.stringify(this.state.selected)
                }
            };
        
            fetch(API_BASE_URL + "/task/save", {
                method: 'post',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                }
            });
        });
    }

    render () {
        return (
            <Row className={classnames(styles.exerciseBox16, this.props.className)}>
                <Col className="text-center">
                    <p className="text-justify wspw">{this.props.params.content}</p>
                    <div className="text-left">
                        {
                            Object.keys(this.props.params).filter(p => p.indexOf("answer-") === 0).sort((a, b) => parseInt(a.replace("answer-", "")) - parseInt(b.replace("answer-", "")))
                                .map(a => {
                                    return (
                                        <div key={a} className={ classnames("checkbox pl-2") }>
                                            <input 
                                                type={"checkbox"}
                                                id={a} 
                                                name={a} 
                                                onChange={() => this.toggleAnswer(a)}
                                                checked = { this.isOptionChecked(a) }
                                            />
                                            <label htmlFor={a}>
                                                <span>{this.props.params[a]}</span>
                                            </label>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </Col>
            </Row>
        )
    }
}

export default L16;