import React from 'react';
import Button from 'react-bootstrap/Button';
import { API_BASE_URL } from '../utils/constants';

class FullVersion extends React.Component {

    state = {
        ordered: false
    }

    onClick = (decision) => {
        console.log(this.props);
        fetch(API_BASE_URL + "/fullversion/" + this.props.logID + "/" + decision, {method: 'post'});
    
        this.setState({
          ordered: true
        });
    }

    render () {
        return (
            <div className="text-center pt-3 pb-5">
                {
                    this.state.ordered
                    ?
                        <h4>Dziękujemy za udzieloną odpowiedź!</h4>
                    :
                    <>
                        <h4>Czy chciałbyś w przyszłości skorzystać z pełnej wersji programu?</h4>
                        <Button onClick={()=>{this.onClick(true)}} className="main">Tak</Button>
                        <Button onClick={()=>{this.onClick(false)}} className="main">Nie</Button>
                    </>
                }
            </div>
        )
    }
}

export default FullVersion;