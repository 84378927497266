import React from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import styles from './L11.module.scss';
import { BsPencil } from "react-icons/bs";
import classnames from 'classnames';
import { API_BASE_URL } from '../utils/constants';
import { MdCancel } from "react-icons/md";
import { Tooltip } from 'react-tippy';

class L11 extends React.Component {

    state = {
        answers: []
    }

    componentDidMount() {
        let answers = [{id: 1, text: ""}];
        try { 
            answers = JSON.parse(this.props.answers.data.answers);
        } catch (e) {}
        this.setState({answers: answers});
    }

    addNew = () => {
        let answers = this.state.answers;
        let maxID = 1;
        for (let i = 0; i < answers.length; i++) {
            if (answers[i].id >= maxID) {
                maxID = answers[i].id + 1;
            }
        }
        answers.push({id: maxID, text: ""});
        this.setState({answers: answers});
    }

    changeValue = (id, value) => {
        let answers = this.state.answers;
        for (let i = 0; i < answers.length; i++) {
            if (answers[i].id === id) {
                answers[i].text = value;
                break;
            }
        }
        this.setState({answers: answers}, this.saveForm);
    }

    removeItem = (id) => {
        let answers = this.state.answers;
        let index;
        for (let i = 0; i < answers.length; i++) {
            if (answers[i].id === id) {
                index = i;
            }
        }
        if (index) {
            answers.splice(index, 1);
        }
        this.setState({answers: answers}, this.saveForm);
    }

    saveForm = () => {
        let data = {
            accessKey: this.props.lessonKey,
            data: {
                answers: JSON.stringify(this.state.answers)
            }
        };
        fetch(API_BASE_URL + "/task/save", {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        });
    }

    render() {
        return (
            <Row className={classnames("exercise-box", styles.exerciseBox11, this.props.className)}>
                <Col className="text-center">
                    <h4><BsPencil /> ZADANIE</h4>
                    <p className="content">{this.props.params.content}</p>
                    <hr/>
                    <div className={"text-left mpx-5"}>
                        {
                            this.state.answers.map(a => {
                                return (
                                    <div key={a.id} className={classnames("checkbox pl-2")}>
                                        <input 
                                            type="checkbox"
                                            id={a.id} 
                                            name={a.id}
                                            disabled={true}
                                            checked = { true }
                                        />
                                        <label className={styles.mlabel} htmlFor={a.id}>
                                            <input placeholder={"Wpisz własne działanie"} onChange={(ev) => {this.changeValue(a.id, ev.target.value)}} value={a.text} className={styles.minput}/>                                               
                                            <Tooltip
                                                title="Usuń"
                                                position="top"
                                                trigger="mouseenter">
                                                <MdCancel onClick={()=>this.removeItem(a.id)} size={30}/>
                                            </Tooltip>
                                        </label>
                                    </div>
                                )
                            })
                        }
                        <Button onClick={this.addNew} className={classnames("main small", styles.buttonAdd)}>+ Dodaj kolejną pozycję</Button>
                        
                    </div>
                </Col>
            </Row>
        )
    }
}

export default L11;