import React from 'react';
import styles from './extension.module.scss';
import Wait from './Wait';
import Button from 'react-bootstrap/Button';
import { API_BASE_URL } from '../utils/constants';

class Extension extends React.Component {

    constructor(props) {
        super(props);
        this.mrefs = [];
    }

    state = {
        waiting: false,
        content: []
    }

    componentDidMount() {
        fetch(API_BASE_URL + "/extension/" + this.props.lessonKey + "/" + this.props.id )
        .then(res => res.json())
        .then(json => {
          if(json.status === 200) {
            this.setState({
                content: json.obj.content
            })
          }})
        .catch((error) => {
          this.props.onClose();
        });  
    }

    componentDidUpdate() {
        if (this.props.section) {
            for (let i = 0; i < this.mrefs.length; i++) {
                if (this.mrefs[i].id === "section" + this.props.section) {
                    this.mrefs[i].scrollIntoView();
                    break;
                }            
            }
        }
      }

      
    
    getExtensionParams = (s) => {
        let params = {
            step: null
        }
        if (s.indexOf(" step=") > -1) {
            let c = s.substring(s.indexOf(" step=") + 6);
            let last = c.indexOf(" ");
            if (last === -1) last = c.indexOf("]");
            params.step = c.substring(0, last);
        }
        return params;
    }

    getFormattedContent = (content, key) => {
        if (!key) {
            key = 1;
        }

        let r = [];
        let firstIndex = content.indexOf("[extension ");
        let lastIndex = content.indexOf("[/extension]");
        if (firstIndex > -1 && lastIndex > -1) {
            let cutted = content.substring(firstIndex, lastIndex);
            let extension = cutted.substring(0, cutted.indexOf("]") + 1);
            cutted = cutted.replace(extension, "");
            r.push(<span key={key++} dangerouslySetInnerHTML={ { __html: content.substring(0, firstIndex) }}></span>);
            let p = this.getExtensionParams(extension);
            if (p.step) {
                // eslint-disable-next-line
                r.push(<a target="_blank" key={key++} className={"extension-link"} href={"/" + this.props.list.filter(el => el.step == p.step)[0].access}>{cutted}</a>);
            } else {
                r.push(<span key={key++} className={"extension-link"} >{cutted}</span>);
            }
            
            r.push(this.getFormattedContent(content.substring(lastIndex + 12), key++));
        } else {
            r.push(<span key={key++} dangerouslySetInnerHTML={ { __html: content }}></span>);
        }
        return r;
    }

    render() {
        if (this.state.waiting) {
            return (
                <div className={ styles.cover }>
                    <div className={ styles.box }>
                        <Wait type="waiting-nested"/>
                    </div>                
                </div>
            )
        }
        return (
            <div className={ styles.cover }>
                <div className={ styles.box }>
                    <div className= { styles.content }>
                        {
                            this.state.content.map(el => {
                                return (
                                    <div id={"section" + el.id} 
                                        ref={elr => this.mrefs.push(elr)} 
                                        className={el.classname} 
                                        key={el.id} >
                                        { this.getFormattedContent(el.value) }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={ styles.footer }>
                        <Button onClick={this.props.onClose} className="main">Zamknij</Button>
                    </div>
                </div>                
            </div>
        )
    }
}

export default Extension;