
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { BsPencil } from "react-icons/bs";
import styles from './L1.module.scss';
import classnames from 'classnames';
import { API_BASE_URL } from '../utils/constants';
import swal from 'sweetalert';

class L1 extends React.Component {
  
    state = {
        submitted: false,
        answers: {}
    }

    reset = (clear) => {
        let answers = {};
        
        if (!clear) {
            try {
                answers = JSON.parse(this.props.answers.data.answers);
            } catch(e) {}
        }
    
        this.setState({
            submitted: Object.keys(answers).length > 0,
            answers: answers
        });
    }

    componentDidMount() {
        this.reset();
    }

    submitForm = () => {
        if (this.state.submitted) {
            return;
        }

        let data = {
            accessKey: this.props.lessonKey,
            data: {
                answers: JSON.stringify(this.state.answers)
            }
        };

        fetch(API_BASE_URL + "/task/save", {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        });

        this.setState({
            submitted: true
        });

        if (this.existsBadAnswer()) {
            swal("", "Spróbuj jeszcze raz. Zaznaczyliśmy na czerwono obszary, które możesz poprawić. Zresetuj ankietę przyciskiem pod nią i spróbuj wykonać ćwiczenie ponownie.", "error");
        } else {
            swal("", "Gratulacje, udało Ci się poprawnie uzupełnić ćwiczenie! Zaproponowane przez Ciebie myśli to właśnie te, które pozwalają nam poczuć się lepiej w trudnej sytuacji. Pamiętaj, że nastawienie, z jakim podchodzimy do danego zagadnienia, wyzwala w nas (lub ogranicza) zasoby do podjęcia działania.", "success");
        }
    }

    existsBadAnswer = () => {
        let value = false;
        Object.keys(this.props.params).filter(p => p.indexOf("question-") === 0)
            .map(o => {
                let j = JSON.parse(this.props.params[o]);
                if (this.state.answers[o] !== j.correct) {
                    value = true;
                }
                return null;
            });

        return value;
    }

    getSaveButton = () => {
        if (this.state.submitted) {
            return (
                <>
                    {
                        this.existsBadAnswer()
                        ?
                        <span>Spróbuj jeszcze raz. Zaznaczyliśmy na czerwono obszary, które możesz poprawić.</span>
                        :
                        <span>Gratulacje, udało Ci się poprawnie uzupełnić ćwiczenie! Zaproponowane przez Ciebie myśli to właśnie te, które pozwalają nam poczuć się lepiej w trudnej sytuacji. Pamiętaj, że nastawienie, z jakim podchodzimy do danego zagadnienia, wyzwala w nas (lub ogranicza) zasoby do podjęcia działania.</span>
                    }
                    <Button onClick={() => {this.reset(true)}} className={"main mt-2"}>
                        Resetuj
                    </Button>
                </>
            );
        } else {
            return (
                <Button onClick={this.submitForm} className={"main mt-2"}>
                    Zapisz odpowiedzi
                </Button>
            )
        }        
    }

    checkAnswer = (o, a) => {
        if (this.state.submitted) {
            return;
        }
        let answers = this.state.answers;
        answers[o] = a;
        this.setState({answers: answers});
    }

    getQuestionClass = (q) => {
        let question = JSON.parse(this.props.params[q]);
        if (!this.state.submitted) {
            return null;
        }

        return this.state.answers[q] === question.correct ? styles.correct : styles.bad;
    }

  render() {
    return (
      <Row className={classnames("exercise-box", styles.exerciseBox1, this.props.className)}>
          <Col className="text-center">
            <h4><BsPencil /> ZADANIE</h4>
            <p className="content">{this.props.params.content}</p>
            <hr/>
            <div style={{overflowX: "auto"}}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>Sytuacja</th>
                        <th>Myśli</th>
                        <th>Emocja</th>
                        <th>Jaka myśl pozwoliłaby bohaterce/bohaterowi poczuć się lepiej?</th>
                        </tr>
                    </thead>
                    <tbody>
                        { Object.keys(this.props.params).filter(p => p.indexOf("question-") === 0).sort((a, b) => parseInt(a.replace("question-", "")) - parseInt(b.replace("question-", "")))
                            .map(o => {
                                let j = JSON.parse(this.props.params[o]);
                                return (
                                <tr key={o} className={this.getQuestionClass(o)}>
                                    <td>{ j.situation }</td>
                                    <td>{ j.thinking }</td>
                                    <td>{ j.emotions }</td>
                                    <td>{ Object.keys(j.answers).map(a => {
                                            return <span 
                                                    key={a}
                                                    onClick={() => this.checkAnswer(o, a)}
                                                    className={classnames(this.state.answers[o] === a ? styles.checked : "")}
                                                >{j.answers[a]}</span>   
                                    })}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            <div className={"submit-row"}>
                { this.getSaveButton() } 
            </div>
          </Col>
      </Row>
    );
  }
}

export default L1;