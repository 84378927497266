
import React from 'react';
import Button from 'react-bootstrap/Button';
import { setCookie, getCookie } from '../utils/utils';
import styles from './jar.module.scss';

class Jar extends React.Component {

    state = {
        drawn: null,
        width: null,
        boxShadow: null
    }

    reset = () => {
        this.setState({
            drawn: null,
            width: null,
            boxShadow: null
        })
    }

    drawPleasure = () => {
        if (this.state.drawn) {
            return;
        }

        let lastDrawnedPleasures = [];
        try { lastDrawnedPleasures = JSON.parse(getCookie(this.props.lessonKey + "_lastdrawned")); } catch(e) {}
        if (lastDrawnedPleasures.length > 3) {
            lastDrawnedPleasures.splice(0, lastDrawnedPleasures.length - 3)
        }

        if (this.props.pleasures.length <= lastDrawnedPleasures.length) {
            let howManyShift = lastDrawnedPleasures.length - this.props.pleasures.length + 1;
            if (howManyShift < 0) {
                howManyShift = 0;
            }
            if (howManyShift > lastDrawnedPleasures.length) {
                howManyShift = lastDrawnedPleasures.length;
            }
            lastDrawnedPleasures.splice(0, howManyShift);
        }

        let pleasures = this.props.pleasures.filter(el => lastDrawnedPleasures.indexOf(el.id) === -1);
        let drawn = pleasures[Math.floor(Math.random() * pleasures.length)];

        if (!drawn) {
            return;
        }

        lastDrawnedPleasures.push(drawn.id);        

        setCookie(this.props.lessonKey + "_lastdrawned", JSON.stringify(lastDrawnedPleasures));

        this.setState({
            drawn: drawn
        }, () => {
            setTimeout(() => {
                this.setState({
                    boxShadow: "0px 0px 20px 20px #00000063",
                    width: "100%"
                })
            }, 200)
            
        });
    }

  render() {
    return (
        <>
        <div className={styles.jarBox}>
                <>
                    {
                    this.state.drawn 
                    ?
                        
                            <div className={styles.jarDrawn}>                                
                                <div
                                    style={{boxShadow: this.state.boxShadow, width: this.state.width}}>
                                    <h5>
                                        {this.state.drawn.text}
                                    </h5>
                                    <Button className="main" onClick={() => {this.reset()}}>
                                        Losuj ponownie
                                    </Button>
                                </div>
                            </div>
                    :
                    null
                    }
                    <h4>
                        Kliknij na słoik, aby wylosować dzisiejszą przyjemność
                    </h4>
                    <div className={styles.box} style={{height: this.props.height}}>
                        <div className={styles.jar} onClick={() => this.drawPleasure() }></div>
                        <div className={styles.leftHandle}></div>
                        <div className={styles.rightHandle}></div> 
                        <div className={styles.top1}></div>
                        <div className={styles.top2}></div>
                        <div className={styles.top3}></div>
                        <div className={styles.notes}>
                        {
                            this.props.pleasures.map(s => {
                            let pos = this.props.positions[s.id];
                            return (
                                <textarea
                                key={s.id}
                                value ={s.text} 
                                readOnly
                                className={styles.selected} 
                                style={{
                                    left: pos.left, 
                                    top: pos.top, 
                                    right: pos.right, 
                                    bottom: pos.bottom, 
                                    transform: pos.transform
                                }}>
                                
                                </textarea>
                            )
                            })
                        }
                        </div>                      
                    </div>
                </>
            
        </div>
        </>
    );
  }
}

export default Jar;