
import React from 'react';

class Wait extends React.Component {

  render() {
    return (
      <div className={(this.props.type || "waiting-box")}>
         <img alt="waiting" src="/images/waiting.svg" />
      </div>
    );
  }
}

export default Wait;