
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styles from './L4.module.scss';
import { API_BASE_URL } from '../utils/constants';
import { checkObjects } from '../utils/utils';
import { BsPencil } from "react-icons/bs";
import { Tooltip } from 'react-tippy';
import classnames from 'classnames';
import swal from 'sweetalert';

class L4 extends React.Component {
  
  state = {
    submitted: false,
    answers: {}
  }

  reset = (clear) => {
    let answers = {};

    Object.keys(this.props.params).filter(p => p.indexOf("question-") === 0).map(q => {
      answers[q] = [];
      return null;
    });

    if (!clear) {
      try { 
        answers = JSON.parse(this.props.answers.data.answers);
      } catch (e) {}
    }

    this.setState({
      submitted: Object.keys(answers).filter(a => answers[a].length > 0).length > 0,
      answers: answers
    });
  }

  componentDidMount() {
    this.reset();
  }

  submitForm = () => {
    if (this.state.submitted) {
        return;
    }

    let data = {
        accessKey: this.props.lessonKey,
        data: {
            answers: JSON.stringify(this.state.answers)
        }
    };

    fetch(API_BASE_URL + "/task/save", {
        method: 'post',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        }
    });

    this.setState({
        submitted: true
    }, () => {
      let existsBadAnswer = false;
      let questions = Object.keys(this.props.params).filter(p => p.indexOf("question-") === 0);
      for (let i = 0; i < questions.length; i++) {
          if (!this.getResult(questions[i], "bool")) {
              existsBadAnswer = true;
              break;
          }
      }

      if (existsBadAnswer) {
          swal("", "Spróbuj jeszcze raz. Zaznaczyliśmy na czerwono obszary, które możesz poprawić. Zresetuj ankietę znajdującym się pod nią przyciskiem i spróbuj wykonać ćwiczenie ponownie.", "error");
      } else {
          swal("", "Gratulacje, udało Ci się poprawnie uzupełnić ćwiczenie!", "success");
      }
  });
  }

  isAnswersForAllQuestions = () => {
    let value = true;
    Object.keys(this.props.params).filter(p => p.indexOf("question-") === 0).map(q => {
      if (!this.state.answers[q] || this.state.answers[q].length === 0) {
        value = false;
      }
      return null;
    });

    return value;
  }

  getSaveButton = () => {
    if (this.state.submitted) {
      return (
        <Button onClick={() => this.reset(true)} className={"main mt-2"}>
            Resetuj
        </Button>
      )
    } else if (this.isAnswersForAllQuestions()) {
      return (
        <Button onClick={this.submitForm} className={"main mt-2"}>
            Sprawdź odpowiedzi
        </Button>
      )
    } else {
      return (
        <Tooltip
          title="Odpowiedz na wszystkie pytania aby sprawdzić poprawne odpowiedzi."
          position="top"
          trigger="click"
        >
          <Button className={"main mt-2"}>
              Sprawdź odpowiedzi
          </Button>
        </Tooltip>
      )
    }
  }

  getOptionChecked = (question, answer) => {
    let a = this.state.answers[question];
    return (a && a.indexOf(answer) > -1) ? true : false;
  }

  toggleAnswer = (question, answer) => {
    let answers = this.state.answers;
    let index = answers[question].indexOf(answer);
    if (index === -1) {
      answers[question].push(answer);
    } else {
      answers[question].splice(index, 1);
    }

    this.setState({answers: answers});
  }

  getResult = (question, type) => {
    if (!this.state.submitted) {
      return null;
    }

    let q = JSON.parse(this.props.params[question]);
    let selected = this.state.answers[question];    
    let correct = q.correct;

    var r = {
      "explanation": [<p className={styles.explanation} dangerouslySetInnerHTML={ { __html: q.explanation }}></p>, null],
      "bool": [false, true]
    }

    if (!checkObjects(selected, correct)) {      
      return r[type][0] 
    } else {
      return r[type][1];
    }
  }

  getCheckboxClass = (correct, answer) => {
    if (!this.state.submitted) {
      return null;
    }

    return correct.indexOf(answer) > -1 ? "correct" : "bad";
  }

  render() {
    return (
      <>
      <Row className={classnames("exercise-box", styles.exerciseBox4, this.props.className)}>
          <Col className="text-center">
            <h4><BsPencil /> ZADANIE</h4>
            <p className="content">{this.props.params.content}</p>
            <hr/>
            <div className={"text-left mpx-5"}>
              {
                Object.keys(this.props.params).filter(p => p.indexOf("question-") === 0).sort((a, b) => parseInt(a.replace("question-", "")) - parseInt(b.replace("question-", "")))
                .map(o => {
                      let j = JSON.parse(this.props.params[o]);
                      return (
                        <div key={o} className="question-box my-3">
                          <h6>{j.question}</h6>
                          {
                            Object.keys(j.answers).map(a => {
                              return (
                                <div key={o + "-" + a} className={classnames("checkbox pl-2", this.getCheckboxClass(j.correct, a))}>
                                    <input 
                                        type={j.type} 
                                        id={o + "-" + a} 
                                        name={o}
                                        disabled={this.state.submitted}
                                        onChange={() => this.toggleAnswer(o, a)}
                                        checked = {this.getOptionChecked(o, a) }
                                    />
                                    <label htmlFor={o + "-" + a}>
                                        <span className={this.state.submitted && j.correct.indexOf(a) > -1 ? styles.correctUnderline : null}>{j.answers[a]}</span>
                                    </label>
                                </div>
                              )
                            })
                          }
                          { this.getResult(o, "explanation") }
                        </div>
                  )
                })
              }
            </div>
            <Row>
              <Col lg={12}>        
                <div className="submit-row">
                  { this.getSaveButton() }         
                </div>
              </Col>
            </Row>
          </Col>
      </Row>
      </>
    );
  }
}

export default L4;