import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'react-tippy/dist/tippy.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './resources/css/bootstrap.min.css';
import {BrowserRouter as Router,Route,Switch,Redirect} from 'react-router-dom';

ReactDOM.render(
  <Router>        
      <Switch>
          <Route path="/:k" component={App}></Route>   
          <Route path="/" component={App}></Route>   
          <Redirect to="/" />        
      </Switch>
  </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
