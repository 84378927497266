

export const checkObjects = ( objA, objB ) => {
    if (objA === objB) return true;

    try {
      if(objA.length !== objB.length) return false;
    } catch(e) {}

    try {
      var cA = objA.slice().sort().join(","); 
      var cB = objB.slice().sort().join(",");

      return cA===cB;
    } catch(e) {}
    return false;
}

export const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}