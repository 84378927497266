
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';
import styles from './L3.module.scss';
import { API_BASE_URL } from '../utils/constants';
import { RiCamera2Line } from "react-icons/ri";
import Wait from '../components/Wait';



class L3 extends React.Component {
  
  state = {
    panicSaving: false,
    modalSaving: false
  }

  saveCalmAudioButton = (decision) => {
    this.setState({panicSaving: true});
    fetch(API_BASE_URL + "/task/panicbutton/" + this.props.lessonKey + "/" + decision, {
        method: 'get'
    })
        .then(res => res.json())
        .then(json => {
            if(json.status === 200) {
                this.props.getLessonData(()=>{
                  this.setState({panicSaving: false});
                });
            } else {
                this.setState({
                    buttonText: "Wystapił nieoczekiwany błąd",
                    buttonState: "completed",
                    checkboxDisabled: ""
                });
            }
        })
        .catch((error) => {
            this.setState({
                buttonText: "Wystapił nieoczekiwany błąd",
                buttonState: "completed",
                checkboxDisabled: ""
            });
        });  
  }

  saveModalGameButton = (decision) => {
    this.setState({modalSaving: true});
    fetch(API_BASE_URL + "/task/modalgamebutton/" + this.props.lessonKey + "/" + decision, {
        method: 'get'
    })
        .then(res => res.json())
        .then(json => {
            if(json.status === 200) {                
              this.props.getLessonData(()=>{
                this.setState({modalSaving: false});
              });
            } else {
                this.setState({
                    buttonText: "Wystapił nieoczekiwany błąd",
                    buttonState: "completed",
                    checkboxDisabled: ""
                });
            }
        })
        .catch((error) => {
            this.setState({
                buttonText: "Wystapił nieoczekiwany błąd",
                buttonState: "completed",
                checkboxDisabled: ""
            });
        });  
  }

  

  render() {
    return (
      <>
      <Row id="exercise3" className={classnames("exercise-box", styles.exerciseBox3, this.props.className)}>
          <Col lg={12} sm={12} className="text-center">            
            <p className="content" style={{color: "#17a2b8", fontSize: "1.1em"}}>
                {this.props.params.content}
            </p>
            <div className={classnames(styles.control, this.props.panicPlay ? styles.pause : styles.play)} onClick={() => {this.props.playPanicText(!this.props.panicPlay)}}>
              <span className={styles.left}></span><span className={styles.right}></span>
            </div>
            {
              this.state.panicSaving
              ?
              <Wait  type="waiting-inline"/>
              :
              !this.props.panicButton ? 
              <>
                <p className={"text-center"} style={{color: "#17a2b8", fontSize: "1.1em"}}>
                  {this.props.params.saveText}
                </p>
                <p className={"text-center"}>
                  <Button className="main" onClick={() => {this.saveCalmAudioButton(true)}}>Zapisz</Button>
                </p>
              </>
              : 
              <>
                <p className={"text-center"} style={{color: "#17a2b8", fontSize: "1.1em"}}>
                  {this.props.params.removeText}
                </p>
                <p className={"text-center"}>
                  <Button className="main" onClick={() => {this.saveCalmAudioButton(false)}}>Usuń</Button>
                </p>
              </>
            
            }
            
          </Col>
      </Row>
      <Row  className={classnames("exercise-box", styles.exerciseBox3, this.props.className)}>
          
        <Col lg={12} sm={12} className="text-center">
              <p className="content"  style={{color: "#17a2b8", fontSize: "1.1em"}}>
                {this.props.params.contentModalGame}
              </p>
              <p className={"text-center"}>
                <RiCamera2Line className={styles.modalGame} onClick={() => { this.props.setModalGame(true) }}/>
              </p>
              { 
                this.state.modalSaving
                ?
                <Wait  type="waiting-inline"/>
                :
                !this.props.modalGameButton ? 
                <>
                  <p className={"text-center"} style={{color: "#17a2b8", fontSize: "1.1em"}}>
                    {this.props.params.saveTextModalGame}
                  </p>
                  <p className={"text-center"}>
                    <Button className="main" onClick={() => {this.saveModalGameButton(true)}}>Zapisz</Button>
                  </p>
                </>
                : 
                <>
                  <p className={"text-center"} style={{color: "#17a2b8", fontSize: "1.1em"}}>
                    {this.props.params.removeTextModalGame}
                  </p>
                  <p className={"text-center"}>
                    <Button className="main" onClick={() => {this.saveModalGameButton(false)}}>Usuń</Button>
                  </p>
                </>
              }
          </Col>
      </Row>
      </>
    );
  }
}

export default L3;